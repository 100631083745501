import request from "@/utils/request";


let urlPrefix = "/adminservice/note/book"

export const findModifiedTimesByPage =(data) =>{
    data.system = "group-notes"
    data.method = "findModifiedTimesByPage"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}
export const getBookWriteInfo =(data) =>{
    data.system = "group-notes"
    data.method = "getBookWriteInfo"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}