
export const timestampToYYYY_MM_DD = (timestamp) => {
    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return year + '-' + month + '-' + date
};

/**
 * 时间戳转换为日期格式
 */
export const timestampToTime = (timestamp) => {
    // let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // let Y = date.getFullYear() + '-'
    // let M =
    //     (date.getMonth() + 1 < 10 ?
    //         '0' + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + '-'
    // let D =
    //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // let h =
    //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m =
    //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    //     ':'
    // let s =
    //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    /*return Y + M + D + h + m + s*/
    // return Y + M + D

    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return year + '-' + month + '-' + date + ' ' + hours + ':' + minute
};
/**
 * 时间戳转换为日期格式
 */
 export const timestampToS = (timestamp) => {
    // let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // let Y = date.getFullYear() + '-'
    // let M =
    //     (date.getMonth() + 1 < 10 ?
    //         '0' + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + '-'
    // let D =
    //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // let h =
    //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m =
    //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    //     ':'
    // let s =
    //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    /*return Y + M + D + h + m + s*/
    // return Y + M + D

    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return year + '-' + month + '-' + date + ' ' + hours + ':' + minute +':'+second
};
/**
 * 时间戳转换为日期格式
 */
 export const timestampToMonth = (timestamp) => {
    // let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // let Y = date.getFullYear() + '-'
    // let M =
    //     (date.getMonth() + 1 < 10 ?
    //         '0' + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + '-'
    // let D =
    //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // let h =
    //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m =
    //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    //     ':'
    // let s =
    //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    /*return Y + M + D + h + m + s*/
    // return Y + M + D

    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return month + '-' + date + ' ' + hours + ':' + minute
};
export const changeTel = (num)=>{
    return num.substr(0,3)+' '+num.substr(3,4)+' '+ num.substr(7,4) 
}

export const commSetting = {
    creator: "22", //作者
    styles: {
        paragraphStyles: [
            // 文档大标题
            {
                id: "Heading1",
                name: "Heading 1",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 32,
                    bold: true,
                    color: "000000",
                },
                paragraph: {
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                },
            },
            // 文档正文
            {
                id: "Heading4",
                name: "Heading 4",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 28,
                    color: "000000",
                },
                paragraph: {
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                },
            },
            // 表格正文标题
            {
                id: "Heading5",
                name: "Heading 5",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 18,
                    color: "000000",
                    bold: true,
                },
                paragraph: {
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                },
            },
            // 表格正文
            {
                id: "Heading6",
                name: "Heading 6",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 18,
                    color: "000000",
                },
                paragraph: {
                    spacing: {
                        before: 180,
                        after: 180,
                    },
                },
            },
        ],
    },
}