import axios from "axios";
/*创建axios 实例*/
const zbhAdminService = axios.create({
    /*api的baseURL*/
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://zbh.zbform.com/"
            : "https://zbh.zbform.com/",
            // :"http://127.0.0.1:8181", 
    /*请求超时时间*/
    timeout: 3600000
});
/*request 拦截器*/
zbhAdminService.interceptors.request.use(
    config => {
        let url = config.url;
        //配置密匙
        if (url.includes("authservice")) {
            config.headers["secret"] = "MCop2ofOHLDjpSnea7cIBJWZe5nabD1QxT3Bp97BPGmz8zGpur7GjylqXC0qNdgH";
        }
        //配置token
        let token = JSON.parse(localStorage.getItem("token"))
        if (url.includes("webgroupservice")) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        if (url.includes("businessservice")) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        if (url.includes("webservice")) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        if (url.includes("adminservice")) {
            config.headers["token"] = token;
        }
        if (config.ContentType) {
            config.headers["Content-Type"] = "multipart/form-data";
        }
        if(config.responseType){
            config.responseType = config.responseType
        }
        return config;
    },
    error => {
        /*处理请求出错*/
        return Promise.reject(error);
    }
);
/*response拦截器*/
zbhAdminService.interceptors.response.use(res => {
    return res;
},
    error => {
        // debugger
        // if (error.response.status === 401) {
        //     window.$router.push("/login")
        //     return;
        // } else 
        console.log(error)
        if (error.response.status === 500) {
            return error.response;
            this.$message.error(error.response.data.msg)
            return;
        } else {
            /*处理response出错逻辑*/
            return Promise.reject(error);
        }

    }
);
export default zbhAdminService;
