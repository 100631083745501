<template>
    <div class="app-container">
        <div style="display: flex;justify-content: space-between;align-items: center;" class="pb10">
            <el-breadcrumb separator="/" class="">
                <el-breadcrumb-item @click="close()"><el-icon>
                        <HomeFilled class="cursor" />
                    </el-icon></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!classRecordId">{{ activeObj.memberName }} - {{ activeObj.qunName }} - {{
                    activeObj.recordName }}</el-breadcrumb-item>
                <el-breadcrumb-item v-if="classRecordId">{{ activeObj.studentName }} - {{ bookData.fileName
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
            <!-- <div class="chosebtn">
                <div>
                    <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                </div>
                <div>
                    <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                </div>
                <div>
                    <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png" alt="">

                </div>
                <div>
                    <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png" alt="">
                </div>
            </div> -->
        </div>
        <div style="height: calc(100vh - 60px);display: flex;">
            <div class="left">
                <el-card style="height: calc(100vh - 60px);">
                    <div style="display: flex;" class="mb10">
                        <el-radio-group size="small" style="width:100%" v-model="fmType" @change="changFmType">
                            <el-radio-button label="全部" value="全部" />
                            <el-radio-button label="书写" value="书写" />
                            <el-radio-button label="优选" value="优选" />
                        </el-radio-group>
                    </div>

                    <!--左侧列表-->
                    <div
                        style="display: flex;flex-wrap:wrap;justify-content: space-between;max-height: calc(100vh - 150px);overflow-y: auto;">
                        <div v-show="fmType == '书写' ? (item.illustrationList.length != 0 || item.strokeList.length != 0) :
                            (fmType == '优选' ? item.goodFlag : true)" :id="'zmd' + index" @click="changeCurrent(index)"
                            class="mb10" v-for="(item, index) in lfDataList.allStrokeList" style="position: relative;">
                            <div v-if="isCanvas"
                                style="position: absolute;right: 0;top: 0;z-index: 999;background: rgb(56, 148, 255);color: #fff;font-size: 12px;padding: 5px 10px;border-radius: 15px;">
                                {{ getPageName(index + 1, bookData.pageCount,
                                    bookData.headPage,
                                    bookData.tailPage) }}</div>
                            <template v-if="!classRecordId">
                                <Canvasbox v-if="isCanvas && bookData.booktype != 1" :bookData="bookData"
                                    :imgList="item.illustrationList" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="userRecordId + '' + index"
                                    :picSrc="this.$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/' + (index + 1) + '.png_original'"
                                    :detail="item.strokeList"></Canvasbox>
                                <Canvasbox v-if="isCanvas && bookData.booktype == 1" :bookData="bookData"
                                    :imgList="item.illustrationList" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="userRecordId + '' + index"
                                    :picSrc="bookData.formUrl + '/' + (index + 1) + '.png'" :detail="item.strokeList">
                                </Canvasbox>
                            </template>
                            <template v-if="classRecordId">
                                <Canvasbox noxk noPbj v-if="isCanvas" :bookData="bookData" :imgList="[]" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="classRecordId + fileId + activeObj.studentId + '' + index"
                                    :picSrc="bookData.imgFileUrl + (index + 1) + '.png_original'"
                                    :detail="item.strokeList">
                                </Canvasbox>
                            </template>
                            <div :class="current == index ? 'bor-lan' : ''"
                                :style="'width: 150px;height: ' + bookData.pageHeight / bookData.pageWidth * 150 + 'px;top: 0;position: absolute;'">

                            </div>

                            <!-- <div class="pageNumbox" v-if="isCanvas">{{ getPageName(index + 1, bookData.pageCount,
                                bookData.headPage,
                                bookData.tailPage) }}</div> -->
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="right" style="position: relative;">
                <div class="chosebtn" style="position: absolute;right: 0;top: -35px;">
                    <div>
                        <el-button size="small" type="primary" @click="isDc = true">生成PDF笔记</el-button>
                    </div>
                    <div class="ml10">
                        <el-button size="small" type="primary" @click="savePic">保存图片</el-button>
                    </div>
                    <div v-show="fmType == '书写'" style="margin: 0 10px;">
                        <el-button size="small" type="primary" @click="changeStarM">{{ nowPageData.goodFlag ? "取消优选" :
                            "优选"
                        }}</el-button>
                    </div>
                    <div v-show="fmType == '书写' && activeObj.hasTagRight" style="margin: 0 10px;">
                        <el-button size="small" type="primary" @click="showTagDialog">
                            标记学生
                        </el-button>
                    </div>
                    <div>
                        <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                    </div>
                    <div>
                        <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                    </div>
                    <div>
                        <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png"
                            alt="">

                    </div>
                    <div>
                        <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png"
                            alt="">
                    </div>
                </div>

                <!--中间展示页-->
                <el-card style="height: 100%;">
                    <div id="bjcan" style="height: calc(100vh - 95px);overflow-y: auto;">
                        <template v-for="(item, index) in dataList.allStrokeList">
                            <div v-show="fmType == '书写' ? (item.illustrationList.length != 0 || item.strokeList.length != 0) :
                                (fmType == '优选' ? item.goodFlag : true)"
                                :class="active == 1 ? 'ac1' : active == 2 ? 'ac2' : 'swbox'" :id="'md' + index"
                                v-if="isShow" :style="'margin:7px;'" @click="changeIndex(index)"
                                style="position: relative;">

                                <el-tag v-if="item.tagName"
                                    style="position: absolute;left: 0;z-index: 30;">{{ item.tagName }}</el-tag>
                                <img v-if="item.goodFlag" src="@/assets/img/optimize.png" alt=""
                                    style="position: absolute;right: 0;z-index: 30;width: 50px;height:50px;">


                                <template v-if="!classRecordId">
                                    <Canvasbox :isBorder="current == index" borderColor="red" borderWidth="3px"
                                        v-if="isCanvas && bookData.booktype != 1" :bookData="bookData"
                                        :imgList="item.illustrationList" :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="userRecordId + 'rg' + index"
                                        :picSrc="this.$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/' + (index + 1) + '.png_original'"
                                        :detail="item.strokeList"></Canvasbox>
                                    <Canvasbox :isBorder="current == index" borderColor="#409eff"
                                        v-if="isCanvas && bookData.booktype == 1" :bookData="bookData"
                                        :imgList="item.illustrationList" :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="userRecordId + 'rg' + index"
                                        :picSrc="bookData.formUrl + '/' + (index + 1) + '.png'"
                                        :detail="item.strokeList">
                                    </Canvasbox>
                                </template>
                                <template v-if="classRecordId">
                                    <Canvasbox :isBorder="current == index" borderColor="#409eff" noxk noPbj
                                        v-if="isCanvas" :bookData="bookData" :imgList="item.illustrationList"
                                        :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="classRecordId + fileId + activeObj.studentId + 'rg' + index"
                                        :picSrc="bookData.imgFileUrl + (index + 1) + '.png_original'"
                                        :detail="item.strokeList">
                                    </Canvasbox>
                                </template>
                                <div class="pageNumbox" v-if="isCanvas">{{ getPageName(index + 1, bookData.pageCount,
                                    bookData.headPage,
                                    bookData.tailPage) }}</div>
                            </div>
                        </template>
                        <el-empty style="width:100%;"
                            v-if="!activeObj.lastWritePage && !classRecordId && fmType == '书写'"
                            description="暂无笔迹数据"></el-empty>
                        <el-empty style="width:100%;" v-if="!activeObj.writeCount && classRecordId && fmType == '书写'"
                            description="暂无笔迹数据"></el-empty>
                    </div>
                    <!-- <div class="chosebtn">
                        <div>
                            <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                        </div>
                        <div>
                            <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                        </div>
                        <div>
                            <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png"
                                alt="">

                        </div>
                        <div>
                            <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png"
                                alt="">
                        </div>
                    </div> -->
                </el-card>
            </div>
            <div class="right-right">
                <div
                    style="height: 100%;overflow-y: auto;border: 4px;border: 1px solid #e4e7ed;background: #fff;box-shadow: 0px 0px 12px rgba(0,0,0,0.12);">
                    <div>
                        <div
                            style="display: flex;font-size: 15px;padding: 5px;align-items: center;justify-content: center;">
                            <!-- <el-icon @click="changeStarM" v-show="fmType == '书写'">
                                <StarFilled v-if="nowPageData.goodFlag"/>
                                <Star v-else/>
                            </el-icon> -->
                            <el-icon class="m15" :size="35">
                                <UserFilled />
                            </el-icon>
                            <div>
                                <div class="ml5">{{ activeObj.memberName }}</div>
                                <div class="ml5">{{ bookData.bookName }}</div>
                            </div>
                        </div>

                        <!--评论列表-->
                        <div style="height: calc(100vh - 175px);overflow-y: scroll;">
                            <div style="text-align: left;padding: 5px;font-size: 14px; margin-bottom: 5px;" class="mb5"
                                v-for="(comment, index) in commentList" :key="'comment_' + index">
                                <div style="display: flex;justify-content: space-between;">
                                    <span>{{ comment.commentUserName }}:</span>
                                    <el-icon v-show="comment.commentUserId==loginUserId"
                                        @click="deleteCommentM(comment, index)">
                                        <Delete />
                                    </el-icon>
                                </div>
                                <div style="word-break: break-word;">{{ comment.content }}</div>
                            </div>
                        </div>

                        <div style="padding: 5px;">
                            <el-input v-model="commentContent" placeholder="请输入评论" class="input-with-select">
                                <template #append>
                                    <el-icon @click="addCommentM" style="cursor: pointer;">
                                        <ChatLineRound />
                                    </el-icon>
                                </template>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <canvasExport style="opacity: 0;" isbook
            :zdyName="activeObj.memberName + '-' + activeObj.qunName + '-' + activeObj.recordName"
            :formCover="this.$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/cover.png_original'"
            :strokeList="pdfStrList" :bookData="bookData" @canvasClose="canvasClosess" v-if="isDc">
        </canvasExport>
        <!-- <PerviewCanvas :currentNum="currentNum" :strokeList="exStrList" :bookData="formActive"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClLfPerview"
            v-if="isLfPerview">
        </PerviewCanvas> -->
        <div v-if="isACanvas" style="opacity:0;position:fixed;">
            <div ref="dyrgplg"
                :style="`width:${1000}px;height:${activeAObj.pageHeight / activeAObj.pageWidth * 1000}px;`">
                <Canvasbox :isBorder="false" :bookData="bookData" :imgList="[]" v-if="isACanvas" :imwidth="1000"
                    :imheight="bookData.pageHeight / bookData.pageWidth * 1000" :canvasid="userRecordId + 'xxx123'"
                    :picSrc="$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/' + (current + 1) + '.png_original'"
                    :detail="dataList.allStrokeList[this.current].strokeList">
                </Canvasbox>
            </div>
        </div>

        <el-dialog @close="tagVisible==false" :title="'标记学生'" v-model="tagVisible" width="300px" top="50px" draggable>
            <el-input v-model="tagName" style="width: 240px" placeholder="输入学生姓名" />
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="doAddTag">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>


    </div>
</template>

<script>
import { notepageData } from "@/api/adminservice/book";
import html2canvas from "html2canvas";
import { timestampToTime } from "@/utils/date";
import { noteotherBooks, noteBookData, noteConditionBooks } from "@/api/adminservice/book";
import { recordDetails } from "@/api/adminservice/classOrganizaMain"
import { modifyTag,queryTags,commentQueryPageComments, addComment, optimize, deOptimize, deleteComment } from "@/api/groupservice/comment";

import echarts from "echarts";

export default {
    name: "taskDetail",
    components: {

    },
    props: {
        teacherBookId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tagVisible:false,
            tagName:'',//标签内容
            activeAObj: {},
            isACanvas: false,
            pdfStrList: [],
            isDc: false,
            fmType: '书写',
            exStrList: [],
            active: 2,
            current: 0,
            isZjShow: false,
            isShow: false,
            timestampToTime,
            bookData: {},
            strokeList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            lfDataList: [],
            zdyWidth: 0,
            zdyHeight: 0,
            isStart: false,
            isTime: false,
            isNull: true,
            userRecordId: '',
            peopleIdList: [],
            isCanvas: false,
            dataPeoList: [],
            activeObj: {},
            qunRecordId: '',
            qunId: '',
            classRecordId: '',
            fileId: '',
            studentId: '',
            stuStrokeList: [],
            commentList: [],//评论数据
            commentContent: '',//增加的评论
            nowPageData: {},//当前选中的页
            formDesignData: [],
            qunName: '',
            loginUserId: '',//当前登录用户

        };
    },
    created() {
        // console.log('fdsdfds')
    },
    mounted() {
        this.loginUserId = JSON.parse(this.$storage.get('userInfo')).userId

        this.bookData = JSON.parse(this.$route.query.bookData)

        if (this.$route.query.classRecordId) {
            this.classRecordId = this.$route.query.classRecordId;
            this.fileId = this.$route.query.fileId;
            this.studentId = this.$route.query.studentId;
            this.activeObj.studentId = this.$route.query.studentId;
        } else {
            this.userRecordId = this.$route.query.userRecordId
            // this.peopleIdList = JSON.parse(this.$route.query.peopleIdList)
            this.qunRecordId = this.$route.query.qunRecordId
            this.qunId = this.$route.query.qunId
            this.getList();

        }

        if (this.$route.query.qunName) {
            this.qunName = this.$route.query.qunName
        }
        // console.log("bookData=", this.bookData);
        // this.dataList.allStrokeList = []
        let arr = []
        let _this = this;

        // for (let index = 0; index < this.bookData.pageCount; index++) {
        //     this.dataList.allStrokeList.push({ illustrationList: [], strokeList: [] })
        // }
        this.getDetail();
        // document.querySelector("#bjcan").addEventListener("scroll", this.handleScroll);
    },
    methods: {
        showTagDialog(){//展示标签弹框
            if(this.dataList.allStrokeList[this.current].tagName){
                this.tagName = this.dataList.allStrokeList[this.current].tagName
            }else{
                this.tagName = ''
            }
            this.tagVisible = true;
        },
        doAddTag(){//增加标签
            let pageNum = this.current + 1;
            let data = {
                userRecordId: this.userRecordId,
                pageNum,
                tagName:this.tagName,
                userId: this.loginUserId
            }

            // console.log("in=",data)

            // return 

            modifyTag(data).then(res=>{
                if(res.data.code=="1"){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });

                    if(this.dataList.allStrokeList[pageNum-1]){
                        this.dataList.allStrokeList[pageNum-1].tagName = this.tagName
                        // console.log(this.dataList.allStrokeList[pageNum-1].tagName)
                    }
                    this.tagName = '';
                    this.tagVisible = false;
                }
            })
        },
        doQueryTags(){
            queryTags({
                recordId:this.userRecordId,
                userId:this.loginUserId
            }).then(res=>{
                let data = res.data.data;
                data.forEach(e=>{
                    if(this.dataList.allStrokeList[e.pageNum-1]){
                        this.dataList.allStrokeList[e.pageNum-1].tagName = e.tagName
                    }
                })
            })
        },
        deleteCommentM(comment, index) {
            let data = {
                id: comment.id
            }
            console.log("comment.id=",comment.id)
            deleteComment(data).then(res => {
                if (res.data.code == "1") {
                    this.queryComments(this.current + 1)
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                }
            });
        },
        savePic() {
            this.isACanvas = false;
            // console.log(this.activeObj);
            // console.log(this.dataList.allStrokeList[this.current]);

            // this.activeObj
            // this.current
            setTimeout(() => {
                this.isACanvas = true;
                setTimeout(() => {
                    console.log(this.$refs.dyrgplg);
                    html2canvas(this.$refs.dyrgplg, {
                        backgroundColor: "#ffffff",
                        useCORS: true,
                        allowTaint: true,
                        scale: 2,
                        dpi: 300
                    }).then(async canvas => {
                        // 生成图片地址
                        var imgUrl = canvas.toDataURL('image/png');
                        // 创建一个a标签，用来下载图片
                        var eleLink = document.createElement('a');
                        // // 转换后的图片地址
                        eleLink.href = imgUrl;
                        // // 文件名设置
                        eleLink.download = this.activeObj.qunName + '-' + this.bookData.bookName + '-P' + (this.current + 1);
                        // // 下载
                        eleLink.click();
                    })
                }, 2000)
            })
        },
        changeStarM() {//优选，取消优选
            let goodFlag = this.dataList.allStrokeList[this.current].goodFlag
            if (goodFlag) {//已优选，取消优选

                let data = {
                    optimizeId: this.dataList.allStrokeList[this.current].optimizeId,
                    userId: JSON.parse(this.$storage.get('userInfo')).userId
                }

                deOptimize(data).then(res => {
                    if (res.data.code == "1") {
                        this.dataList.allStrokeList[this.current].goodFlag = false;
                        this.$message({
                            message: '取消优选成功',
                            type: 'success'
                        });
                    }
                });
            } else { //未优选，进行优选

                let data = {
                    recordId: this.userRecordId,
                    reason: '优选',
                    pageNum: this.current + 1,
                    userId: JSON.parse(this.$storage.get('userInfo')).userId
                }

                optimize(data).then(res => {
                    if (res.data.code == "1") {
                        this.dataList.allStrokeList[this.current].goodFlag = true;
                        this.dataList.allStrokeList[this.current].optimizeId = res.data.msg

                        // console.log(res.data.msg)
                        // console.log(this.dataList.allStrokeList[this.current].optimizeId)

                        this.$message({
                            message: '优选成功',
                            type: 'success'
                        });
                    }
                });
            }
        },
        addCommentM() { //评论
            var userRecordId = this.userRecordId
            // userRecordId = "29a1b8bc-70af-4645-87b8-751584caf1d7";
            var pageNum = this.current + 1;
            var data = {
                userRecordId,
                pageNum,
                content: this.commentContent,
                userId: JSON.parse(this.$storage.get('userInfo')).userId
            }
            addComment(data).then(res => {//新增评论
                if (res.data.code == "1") {
                    this.$message({
                        message: '评论成功',
                        type: 'success'
                    });
                    this.commentContent = '';
                    this.queryComments(pageNum);//查询评论
                }
            })
        },
        queryComments(pageNum) { //查询评论
            var userRecordId = this.userRecordId
            // userRecordId = "29a1b8bc-70af-4645-87b8-751584caf1d7";
            commentQueryPageComments({
                pageNum, userRecordId: userRecordId,
                userId: JSON.parse(this.$storage.get('userInfo')).userId
            }).then(res => {
                this.commentList = res.data.data.commentList
            })
        },
        canvasClosess(e) {
            this.isDc = false;
            // setTimeout(()=>{
            //     window.location.reload();
            // },3000)
        },
        close() {
            window.close()
        },
        changFmType() {
            this.isShow = false;
            setTimeout(() => {
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            })
        },
        changePeople(item) {
            this.isCanvas = false;
            // this.bookData = item.book;
            console.log(item);
            this.activeObj = item;
            this.userRecordId = item.id;
            this.getDetail();
        },
        getList() {
            var noteBook = []
            var obj = {}
            this.dataPeoList = [];
            var sjobj = JSON.parse(this.$route.query.queryParams)
            noteConditionBooks(sjobj).then(res => {
                // console.log("data=",res);
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.lastWritePage
                })
                this.dataPeoList = res.data.data;
                this.dataPeoList.forEach(e => {
                    if (e.id == this.userRecordId) {
                        this.activeObj = e;
                    }
                })
            })
            // noteotherBooks({}).then(res => {
            //     res.data.data.forEach(e => {
            //         e.qunList.forEach(j => {
            //             j.qunBookList.forEach(m => {
            //                 m.bookList.forEach(x => {
            //                     x.orgId = e.orgId;
            //                     x.orgName = e.orgName;
            //                     x.qunId = j.qunId;
            //                     x.qunName = j.qunName;
            //                     noteBook.push(x);
            //                     var txt = x.resourceId;
            //                     obj[x.resourceId] = []
            //                 })
            //             })
            //         })
            //     })
            //     console.log(noteBook)
            //     noteBook.forEach(e => {
            //         obj[e.resourceId].push(e)
            //     })
            //     console.log(obj[this.bookData.id])
            //     // obj[this.bookData.id] = obj[this.bookData.id].filter(itemA => {
            //     //     if (itemA.id == this.userRecordId) {
            //     //         this.activeObj = itemA;
            //     //     }
            //     //     return this.peopleIdList.indexOf(itemA.id) !== -1;
            //     // })
            //     console.log(obj[this.bookData.id])
            //     this.dataPeoList = obj[this.bookData.id];
            // })

        },
        // 滚动函数事件
        handleScroll() {
            if (!this.isTime) {
                if (this.isNull) {
                    let scrollTop = document.querySelector("#bjcan").scrollTop; //获取监听指定区域滚动位置的值
                    console.log(scrollTop, Math.round(scrollTop / document.getElementById("md0").clientHeight), document.getElementById("md0").clientHeight)
                    if (Math.round(scrollTop / document.getElementById("md0").clientHeight) - 1 < 0) {
                        this.current = 0
                    } else {
                        this.current = Math.round(scrollTop / document.getElementById("md0").clientHeight)
                    }

                    location.href = "#zmd" + (this.current);
                    this.isNull = false;

                    setTimeout(() => {
                        this.isNull = true;
                    }, 500)
                }

            }
        },
        changepageWidth() {
            this.active = 1;
            this.isShow = false;
            setTimeout(() => {
                this.zdyWidth = (document.getElementById("bjcan").clientWidth - 100)
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            });
        },
        changepageHeight() {
            this.active = 1;
            this.isShow = false;
            setTimeout(() => {
                this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            });
        },
        changeActive(num) {
            if (this.active != num || !this.isStart) {
                // this.current = 0;
                this.isShow = false;

                this.active = num

                setTimeout(() => {
                    console.log(document.getElementById("bjcan").clientWidth)
                    console.log(document.getElementById("bjcan").clientHeight)
                    this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                    if (this.active == 3) {
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth - num * 20) / num
                    } else if (this.active == 2) {
                        console.log(document.getElementById("bjcan").clientWidth);
                        // this.zdyHeight = document.getElementById("bjcan").clientHeight - 90;
                        // this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth * 0.8) / num
                        console.log(this.zdyWidth);

                    } else {
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                    }
                    this.isShow = true;
                    setTimeout(() => {
                        location.href = "#md" + this.current;
                        location.href = "#zmd" + this.current;
                    })

                    console.log(this.zdyWidth)
                })
            }



        },
        changeIndex(index) {
            this.current = index;
            location.href = "#zmd" + this.current;

            this.nowPageData = this.dataList.allStrokeList[this.current]
            this.queryComments(index + 1)
        },
        // 取消按钮
        cancel() {
            // this.$emit('isTg', true)
        },
        changeCurrent(index) {
            this.queryComments(index + 1);
            this.current = index;
            this.nowPageData = this.dataList.allStrokeList[this.current]
            this.isTime = true;
            setTimeout(() => {
                this.isTime = false;
            }, 1000)
            location.href = "#md" + index;
        },
        getDetail() {
            // console.log(this.bookData);
            this.dataList.allStrokeList = []
            this.lfDataList.allStrokeList = []
            let arr = []
            let _this = this;

            for (let index = 0; index < this.bookData.pageCount; index++) {
                this.dataList.allStrokeList.push({ illustrationList: [], strokeList: [] })
                this.lfDataList.allStrokeList.push({ illustrationList: [], strokeList: [] })
                this.formDesignData.push({})
            }
            if (this.classRecordId) {
                this.isCanvas = false;
                // this.dataPeoList = [];
                recordDetails({ recordId: this.classRecordId }).then(res => {
                    let arr = []
                    this.stuStrokeList = res.data.data.stuStrokeList;
                    this.stuStrokeList.forEach((e, i) => {
                        if (e.fileId == this.fileId) {
                            if (this.dataPeoList.length == 0) {
                                this.dataPeoList = e.stuStrokeList;
                            }
                        }
                        e.pageWidth = e.fileWidth * 80 / 3;
                        e.pageHeight = e.fileHeight * 80 / 3;
                        e.stuStrokeList.forEach((j, k) => {

                            j.linkList.forEach((m, n) => {
                                var _this = this;
                                // console.log(e.fileId);
                                if (j.studentId == this.activeObj.studentId && e.fileId == this.fileId) {
                                    arr.push(new Promise(function (resolve, reject) {
                                        const xhr = new XMLHttpRequest();
                                        xhr.overrideMimeType("text/plain");
                                        xhr.open('GET', m.link, true);
                                        xhr.onreadystatechange = function () {
                                            if (xhr.readyState === 4 && xhr.status === 200) {
                                                const text = xhr.responseText;
                                                var res = JSON.parse(text);
                                                j.bookData = {
                                                    pages: e.pages,
                                                    pageWidth: e.pageWidth,
                                                    pageHeight: e.pageHeight
                                                }
                                                m.dotsList = res
                                                _this.dataList.allStrokeList[m.page - 1].strokeList = res;
                                                _this.lfDataList.allStrokeList[m.page - 1].strokeList = JSON.parse(JSON.stringify(res));
                                                // _this.bsStrokeList.push({ dotsList: res, bookData: { fileId: e.fileId, pageWidth: e.fileWidth * 80 / 3, pageHeight: e.fileHeight * 80 / 3, page: j.page, imgFileUrl: e.imgFileUrl } })
                                                resolve(res);
                                                // 处理读取到的文本数据
                                            }
                                        };
                                        xhr.send(null);
                                    }))
                                    this.activeObj = j;
                                }

                            })
                        })
                    })
                    Promise.all(arr).then((result) => {
                        // console.log(this.bsStrokeList);
                        this.changeActive(this.active)
                        setTimeout(() => {
                            this.isStart = true;
                            this.isCanvas = true;

                            if (this.activeObj.writeCount) {
                                setTimeout(() => {
                                    this.changeCurrent(Number(this.activeObj.linkList[0].page) - 1)
                                    location.href = "#zmd" + (Number(this.activeObj.linkList[0].page) - 1);
                                })

                            } else {
                                this.changeCurrent(0)
                                location.href = "#zmd" + (0);
                            }
                        }, 1000)
                    })
                })

            } else {
                noteBookData({ userRecordId: _this.userRecordId }).then(res => {
                    res.data.data.forEach(e => {
                        this.dataList.allStrokeList[e.pageNum - 1].illustrationList = e.illustrationList;
                        this.lfDataList.allStrokeList[e.pageNum - 1].illustrationList = JSON.parse(JSON.stringify(e.illustrationList));
                        this.dataList.allStrokeList[e.pageNum - 1].strokeList = e.strokeList;
                        this.lfDataList.allStrokeList[e.pageNum - 1].strokeList = JSON.parse(JSON.stringify(e.strokeList));

                        //优选，优选ID
                        this.dataList.allStrokeList[e.pageNum - 1].goodFlag = e.goodFlag;
                        this.dataList.allStrokeList[e.pageNum - 1].optimizeId = e.optimizeId;

                        //左侧列表
                        this.lfDataList.allStrokeList[e.pageNum - 1].goodFlag = e.goodFlag;
                        this.lfDataList.allStrokeList[e.pageNum - 1].optimizeId = e.optimizeId;

                        this.formDesignData[e.pageNum - 1] = e.strokeList

                        this.doQueryTags();

                    })
                    this.isZjShow = true
                    this.changeActive(this.active)
                    setTimeout(() => {
                        this.isStart = true;
                        this.isCanvas = true;
                        // console.log(3232, this.dataList.allStrokeList);

                        this.dataList.allStrokeList.forEach((e, i) => {
                            if (e.illustrationList.length != 0 || e.strokeList.length != 0) {
                                this.pdfStrList.push({ pageNum: i + 1, strokeList: e.strokeList, illustrationList: e.illustrationList })
                            }
                        })
                        if (this.activeObj.lastWritePage) {
                            setTimeout(() => {
                                this.changeCurrent(this.activeObj.lastWritePage - 1)
                                location.href = "#zmd" + (this.activeObj.lastWritePage - 1);
                            })

                        } else {
                            this.changeCurrent(0)
                            location.href = "#zmd" + (0);
                        }
                    }, 2000)
                })
            }

        },
        getPageName(currentpageNum, totalPages, headPages, tailPages) {
            var pageNum = null;
            var tailpageNum = totalPages - currentpageNum;

            if (tailPages > tailpageNum) {
                // 尾页
                if (tailPages - tailpageNum == 0) {
                    // 尾页最后一页
                    pageNum = "尾页-" + tailPages;
                } else {
                    pageNum = "尾页-" + (tailPages - tailpageNum);
                }
            } else if (headPages >= currentpageNum) {
                // 扉页
                pageNum = "扉页-" + currentpageNum
            } else {
                // 正常页码
                pageNum = "第" + (currentpageNum - headPages) + "页"
            }
            return pageNum;
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.left {
    width: 205px;
    height: 100%;
    margin-right: 15px;
}

.right-right {
    min-width: 133px;
    max-width: 202px;
    height: 100%;
    margin-left: 15px;
}

.right {
    flex: 1;
}

.swbox {
    margin-bottom: 10px;
    flex: 1;
}

.pageNumbox {
    text-align: center;
    padding: 10px 0 25px 0;
}

.bor-lan {
    border: 3px solid red
}

.chosebtn {
    /* padding-top: 10px; */
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: flex-end;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

#bjcan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ac1 {
    width: 100% !important;
}

.ac2 {
    width: 45%;
}

.cstxt {
    padding: 0 15px;
    line-height: 50px;
    border: 1px solid #e3e3e3;
    text-align: center;
}
</style>