<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">{{ !isLibrary ? '新增群组' : '创建学校图书角群组' }}</el-button>
                    <el-button type="primary" @click="handleModel('gly')">配置管理员</el-button>
                    <el-button type="primary" @click="handleModel('scy')">配置视察员</el-button>
                    <el-button type="primary" @click="handleAddBook()">创建本子</el-button>
                    <el-button class="ml20" v-if="!isLibrary||true" type="primary"
                        @click="downloadTemplate">下载导入成员模板</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-button type="primary" @click="changeOrg()">刷新</el-button>
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="85px" @submit.native.prevent="changeOrg()">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0" v-if="!role.includes(1)">
                                        <el-form-item label="客户名称" class="w-100" prop="termId">
                                            <el-select class="w-100" @change="changeCustom"
                                                v-model="queryParams.customId" placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="组织" class="w-100" prop="orgName">
                                            <el-tree-select clearable class="w-100" ref="tsd" default-expand-all
                                                node-key="orgId" v-model="queryParams.orgId" check-strictly
                                                :data="orgChooseList" placeholder="请选择组织" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="启停标志" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.isStop"
                                                placeholder="请选择">
                                                <el-option label="启用" :value="0" />
                                                <el-option label="停用" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <el-row>
                                <div style="margin:0 auto;">
                                    <el-button @click="changeOrg()" type="primary">查找</el-button>
                                </div>
                            </el-row>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row>
                    <el-col :span="8" class="mr0">
                        <el-form-item class="8" label="客户名称" prop="termId">
                            <el-select @change="changeCustom" v-model="queryParams.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mr0">
                        <el-form-item label="组织" prop="orgName">
                            <el-tree-select ref="tsd" default-expand-all node-key="orgId" clearable @change="changeOrg"
                                v-model="queryParams.orgId" check-strictly :data="orgChooseList" placeholder="请选择组织" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e mt15" style="position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-table header-cell-class-name="bgf2" highlight-current-row class=""
                    :height="'calc(100vh - ' + (isSxShow ? '275' : '270') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" width="" :label="isLibrary ? '图书角名称' : '群组名称'" prop="qunName">
                        <template #default="scope">
                            <div :title="scope.row.qunName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.qunName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="!isLibrary" align="center" label="组织结构" prop="orgName">
                        <template #default="scope">
                            <div :title="scope.row.orgName" class="text-one">
                                {{ scope.row.orgName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120" label="群主账号" prop="adminAccount">
                        <template #default="scope">
                            <!-- {{ scope.row.adminAccount.substr(0, 3) + ' ' + scope.row.adminAccount.substr(3, 4) + ' ' +
                            scope.row.adminAccount.substr(7, 4) }} -->
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="110" label="群主姓名" prop="adminName" />
                    <el-table-column align="center" width="110" label="成员数量" prop="memberCount" />
                    <el-table-column align="center" width="120" label="云本配置" prop="bookIds">
                        <template #default="scope">
                            <el-button type="primary" @click.prevent="readbookIds(scope.row)">
                                云本类型
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="" label="编辑">
                        <template #default="scope">
                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="180" label="操作">
                        <template #default="scope">
                            <div style="display: flex;" class="disnone">
                                <el-upload v-model:file-list="filesList" v-if="!isLibrary||true"
                                    action="https://zbh.zbform.com/adminservice/qun/batchImportMember"
                                    :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                    :data="{ qunId: scope.row.id }" accept=".xlsx" :before-upload="beforeUpload">
                                    <el-button type="primary">导入成员</el-button>
                                </el-upload>

                                <el-popconfirm v-if="scope.row.isStop == 0" confirm-button-text="是"
                                    cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF" title="确定停用该客户"
                                    @confirm="handleStop(scope.row)">
                                    <template #reference>
                                        <el-button class="ml10" size="mini" type="danger">停用
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                                <el-popconfirm v-if="scope.row.isStop == 1" confirm-button-text="是"
                                    cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF" title="确定启用该客户"
                                    @confirm="handleStop(scope.row)">
                                    <template #reference>
                                        <el-button class="ml10" size="mini" type="primary">启用
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </div>

                            <!-- <el-popconfirm v-if="scope.row.isStop == 0" confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定停用该客户" @confirm="handleStop(scope.row)">
                            <template #reference>
                                <el-button size="mini" type="text" icon="el-icon-switch-button">停用
                                </el-button>
                            </template>
                        </el-popconfirm>
                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定删除该客户" @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button size="mini" type="text" icon="el-icon-delete">删除
                                </el-button>
                            </template>
                        </el-popconfirm> -->

                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'云本资源'" v-model="readBook" width="60%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="item in readBookList" class="csbbb">
                    <img style="width: 100px;margin-top: 10px;"
                        :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                        class="image">
                    <div style="width: 100px;text-align: center;" class="text-one mb15">{{ item.bookName }}</div>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            :title="modelTypeObj.modelType == 'gly' ? '配置管理员' : modelTypeObj.modelType == 'scy' ? '配置视察员' : '创建本子'"
            v-model="modelTypeObj.isModel" width="60%">
            <div>
                <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                    <el-row>
                        <el-col>
                            <el-form-item label="群组" prop="qunIdsList">
                                <el-tree-select multiple style="width: 100%;" ref="tsd" default-expand-all
                                    node-key="orgId" clearable v-model="form.qunIdsList" check-strictly :data="orgList"
                                    placeholder="请选择群组" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item :label="modelTypeObj.modelType == 'gly' ? '管理员' : '视察员'" prop="adminList">
                                <el-table ref="adminList" border :data="adminList" style="width: 600px"
                                    max-height="430">
                                    <el-table-column align="center" type="index" label="序号" width="60" />
                                    <el-table-column align="center" prop="username"
                                        :label="modelTypeObj.modelType == 'gly' ? '管理员账号' : '视察员账号'">
                                        <template #default="scope">
                                            <el-input @blur="validateAdminUsername(scope.row)"
                                                v-model="scope.row.username" placeholder="最多16个字" maxlength="16" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="managerName"
                                        :label="modelTypeObj.modelType == 'gly' ? '管理员姓名' : '视察员姓名'">
                                        <template #default="scope">
                                            <el-input v-model="scope.row.managerName" placeholder="最多16个字"
                                                maxlength="16" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" label="操作" width="160">
                                        <template #default="scope">
                                            <el-button link type="primary" size="small"
                                                @click.prevent="deleteAdminRow(scope.$index)">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div style="width: 100%;">
                                    <el-button plain type="primary" class="mt10" style="width: 600px"
                                        @click="onAdminAddItem">增加</el-button>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="modelSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="modelTypeObj.isModel = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建本子'" v-model="isAddBook" width="60%">
            <div>
                <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                    <el-row>
                        <el-col>
                            <el-form-item label="组织名称" prop="orgId">
                                <el-tree-select @change="changeBookOrg" style="width: 100%;" ref="tsd"
                                    default-expand-all node-key="orgId" clearable v-model="form.orgId" check-strictly
                                    :data="orgBookList" placeholder="请选择组织" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item :label="isLibrary ? '图书角名称' : '群组名称'" prop="qunIdsList">
                                <el-select multiple style="width: 100%;" clearable v-model="form.qunIdsList"
                                    placeholder="请选择群组">
                                    <el-option v-for="item in qunList" :key="item.qunName" :label="item.qunName"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="云本" prop="resourceId" class="custom">
                                <el-radio-group v-model="form.resourceId">
                                    <el-radio :label="item.id" v-for="item in bookOrgList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="云本名称" prop="recordName">
                                <el-input v-model="form.recordName" placeholder="最多16个字" maxlength="16" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="群内共享" prop="isShare">
                                <el-switch v-model="form.isShare" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="modelSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isAddBook = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg, customOrgBook } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { qunQueryUser, qunReissueMember, qunConfigAdmin, qunConfigViewer, qunBatchCreateQunRecord, qunStopOrUse } from "@/api/adminservice/qun"

export default {
    name: "libraryGroupSet",
    components: {
    },
    data() {
        return {
            isLibrary: false,
            visible: false,
            isSxShow: true,
            bookOrgList: [],
            qunList: [],
            adminList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                isStop: 0,
                customName: '',
                orgName: '',
                orgId: '',
            },
            // 表单参数
            form: {
                qunIdsList: [],
                isShare: false,
                orgId: '',
                resourceId: '',
                recordName: ''
            },
            // 表单校验
            rules: {
                orgId: [
                    { required: true, message: "请选择组织", trigger: "change" },
                ],
                qunIdsList: [
                    { required: true, message: "请选择群组", trigger: "change" },
                ],
                resourceId: [
                    { required: true, message: "请选择云本", trigger: "change" },
                ],
                recordName: [
                    { required: true, message: "请输入云本名称", trigger: "blur" },
                ],
                adminList: [
                    { required: true, validator: this.checkAdminValidator }
                ],
            },
            orgList: [],
            orgChooseList: [],
            orgBookList: [],
            customList: [],
            modelTypeObj: {
                isModel: false,
                modelType: ''
            },
            isAddBook: false,
            role: '',
        };
    },
    created() {
        if (this.$router.currentRoute._value.path == '/libraryGroupSet') {
            this.isLibrary = true;
        }
        this.role = this.$storage.get("role")
        this.getList();
    },
    watch: {
        $route(to, form) {
            console.log(to, form);

            if (this.$router.currentRoute._value.path == '/libraryGroupSet') {
                console.log(11111)
                this.isLibrary = true;
                if (form.path == '/groupSet') {
                    this.getList();
                    this.queryParams.orgId = '';
                    this.queryParams.isStop = 0;
                    this.dataList = [];
                }
            }
            if (this.$router.currentRoute._value.path == '/groupSet') {
                console.log(22222)
                this.isLibrary = false;
                if (form.path == '/libraryGroupSet') {
                    this.getList();
                    this.queryParams.orgId = '';
                    this.queryParams.isStop = 0;
                    this.dataList = [];
                }
            }
        },
    },
    activated() {
        console.log('fdssfd', this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$storage.get("role").includes(1)) {
                this.queryParams.customId = this.$storage.get("customId")
                this.changeCustom()
            } else {
                if (this.$route.query.customId) {
                    this.queryParams.customId = this.$route.query.customId
                    this.changeCustom()
                } else {
                }
            }

        }
    },
    mounted() {
        if (this.$storage.get("role").includes(1)) {
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        } else {
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
        // this.getList();

        this.getBookList();

    },
    methods: {
        modelSubmitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    console.log(this.adminList);

                    if (this.modelTypeObj.modelType == 'gly') {
                        var obj = {
                            qunIds: '',
                            userList: []
                        }
                        obj.qunIds = this.form.qunIdsList.join(",")
                        obj.userList = this.adminList;
                        qunConfigAdmin(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("操作成功");
                                this.form.qunIdsList = [];
                                this.modelTypeObj.isModel = false;
                            } else {
                                if (res.data.msg) {
                                    this.$message.error(res.data.msg)
                                } else {
                                    this.$message.error("操作失败")
                                }
                            }
                        })
                    } else if (this.modelTypeObj.modelType == 'scy') {
                        var obj = {
                            qunIds: '',
                            userList: []
                        }
                        obj.qunIds = this.form.qunIdsList.join(",")
                        obj.userList = this.adminList;
                        qunConfigViewer(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("操作成功");
                                this.form.qunIdsList = [];
                                this.modelTypeObj.isModel = false;
                            } else {
                                if (res.data.msg) {
                                    this.$message.error(res.data.msg)
                                } else {
                                    this.$message.error("操作失败")
                                }
                            }
                        })
                    } else if (this.modelTypeObj.modelType == 'cjbz') {
                        var obj = {
                            qunIds: this.form.qunIdsList,
                            isShare: 0,
                            recordName: this.form.recordName,
                            resourceId: this.form.resourceId
                        }
                        if (this.form.isShare) {
                            obj.isShare = 1
                        } else {
                            obj.isShare = 0
                        }
                        qunBatchCreateQunRecord(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("操作成功");
                                this.form.isShare = false;
                                this.form.orgId = '';
                                this.form.qunIdsList = [];
                                this.form.resourceId = '';
                                this.bookOrgList = [];
                                this.form.recordName = '';
                                this.isAddBook = false;
                            } else {
                                if (res.data.msg) {
                                    this.$message.error(res.data.msg)
                                } else {
                                    this.$message.error("操作失败")
                                }
                            }
                        })
                    }
                }
            });
        },
        checkAdminValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.adminList.length; i++) {
                var e = this.adminList[i];
                if (e.username == '' || e.managerName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        deleteAdminRow(index) {
            // if (this.adminList.length == 1) {
            //     this.$message.error("不能全部删除")
            // } else {
            this.adminList.splice(index, 1)
            // }
        },
        onAdminAddItem() {
            this.adminList.push({
                username: '',
                managerName: '',
            })
            setTimeout(() => {
                this.$refs.adminList.setScrollTop(1000000);
            })
        },
        validateAdminUsername(value) {
            console.log(value);
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.managerName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (!value.managerName) {
                        value.managerName = res.data.data.nickName
                    }
                    value.userId = res.data.data.userId
                }
            })
        },
        changeBookOrg() {
            this.form.qunIdsList = []
            this.form.resourceId = '';

            queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                res.data.data.forEach(e => {
                    console.log(e.orgId)

                    // console.log(this.findParentNode(e.orgId))

                    e.orgName = this.findParentNode(e.orgId).join(' -> ')
                    e.orgIds = this.findParentIdsNode(e.orgId).join(',')
                    // console.log(434,this.findParentNode(e.orgId))
                })
                if (this.form.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgIds.indexOf(this.form.orgId) != -1
                    })
                }
                this.qunList = res.data.data;
            })
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookOrgList = res.data.data;
            })
        },
        changeOrg() {
            this.loading = true;
            console.log(this.queryParams);
            queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                res.data.data.forEach(e => {
                    console.log(e.orgId)

                    // console.log(this.findParentNode(e.orgId))

                    e.orgName = this.findParentNode(e.orgId).join(' -> ')
                    e.orgIds = this.findParentIdsNode(e.orgId).join(',')
                    // console.log(434,this.findParentNode(e.orgId))
                })
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgIds.indexOf(this.queryParams.orgId) != -1
                    })
                }
                if (this.queryParams.isStop !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isStop == this.queryParams.isStop
                    })
                }
                if (this.isLibrary) {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.groupType == 1
                    })
                } else {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.groupType != 1
                    })
                }
                this.dataList = res.data.data;
                this.loading = false;
            })
        },
        goDetail(row) {
            if (this.isLibrary) {
                this.$router.push({
                    path: "/libraryGroupSetDetail",
                    query: {
                        customId: this.queryParams.customId,
                        id: row.id,
                        orgId: row.orgId
                    }
                });
            } else {
                this.$router.push({
                    path: "/groupSetDetail",
                    query: {
                        customId: this.queryParams.customId,
                        id: row.id,
                        orgId: row.orgId
                    }
                });
            }
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E7%BE%A4%E6%88%90%E5%91%98%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '群组导入成员模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIdName = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIdName = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIdName.push(a.name);
                        });
                        parentIdName.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIdName

        },
        findParentIdsNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的
            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));
            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();
                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.id);
                        });
                        parentIds.push(item.orgId);
                    }
                    // 结束遍历
                    parentIdsQueue = [];
                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];
                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }
                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });
                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }
                }
            }
            // console.log(parentIds)
            return parentIds
        },
        changeCustom() {
            // this.loading = true;
            this.orgChooseList = [];
            this.queryParams.orgId = '';
            this.orgChooseList = [];
            this.orgBookList = [];
            queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {
                var orgChooseList = JSON.parse(JSON.stringify(res.data.data));
                orgChooseList.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    // e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgChooseList = orgChooseList;

                var orgBookList = JSON.parse(JSON.stringify(res.data.data));
                orgBookList.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergoBookdicList(e.childrenList)
                })
                this.orgBookList = orgBookList;

                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodzdyicList(e.childrenList)
                })
                this.orgList = res.data.data;
                // console.log(4343,this.findParentNode('f66df71c0ee9425d920132c40b7bba2e'))

                // queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                //     res.data.data.forEach(e => {
                //         console.log(e.orgId)
                //         // console.log(this.findParentNode(e.orgId))
                //         e.orgName = this.findParentNode(e.orgId).join(' -> ')
                //         e.orgIds = this.findParentIdsNode(e.orgId).join(',')
                //         // console.log(434,this.findParentNode(e.orgId))
                //     })
                //     if (this.queryParams.orgId !== '') {
                //         res.data.data = res.data.data.filter(itemA => {
                //             return itemA.orgIds.indexOf(this.queryParams.orgId) != -1
                //         })
                //     }
                //     this.dataList = res.data.data;
                //     this.loading = false;
                // })
            })
        },
        ergodzdyicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId

                    // if (this.findParentzdyNode(e.orgId).indexOf(2) == -1) {
                    //     e.disabled = true
                    // }
                    e.disabled = true

                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = false

                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        // e.children = e.children.filter(itemA => {
                        //     return itemA.orgType != 5;
                        // })
                        this.ergodzdyicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentzdyNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            // this.loading = true;
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e, i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i, 1)
                        this.customList.unshift(e)
                    }
                })
                if (!this.$route.query.customId) {
                    if (this.$storage.get("role").includes(1)) {
                        this.queryParams.customId = this.$storage.get("customId")
                        this.changeCustom()
                    } else {
                        this.queryParams.customId = this.customList[0].customId;
                        this.changeCustom()
                    }

                } else {
                }
            });
        },

        // 停用
        handleStop(row) {
            qunStopOrUse({ qunId: row.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    if (row.isStop == 0) {
                        row.isStop = 1
                    } else {
                        row.isStop = 0
                    }
                    // this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            delCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            if (this.$storage.get('role').includes(1) && !this.isLibrary) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            if (this.isLibrary) {
                this.$router.push({
                    path: "/libraryGroupSetAdd",
                    query: {
                        customId: this.queryParams.customId,
                    }
                });
            } else {
                this.$router.push({
                    path: "/groupSetAdd",
                    query: {
                        customId: this.queryParams.customId,
                    }
                });
            }

            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleModel(type) {
            this.adminList = [];
            this.form.qunIdsList = [];
            this.modelTypeObj.modelType = type;
            this.modelTypeObj.isModel = true;
        },
        handleAddBook() {
            if (this.$storage.get('role').includes(1) && !this.isLibrary) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.modelTypeObj.modelType = 'cjbz';

            this.form.isShare = false;
            this.form.orgId = '';
            this.form.qunIdsList = [];
            this.form.resourceId = '';
            this.bookOrgList = [];
            this.form.recordName = '';
            this.isAddBook = true;
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            if (this.isLibrary) {
                this.$router.push({
                    path: "/libraryGroupSetAdd",
                    query: {
                        customId: this.queryParams.customId,
                        id: row.id,
                        orgId: row.orgId
                    }
                });
            } else {
                this.$router.push({
                    path: "/groupSetAdd",
                    query: {
                        customId: this.queryParams.customId,
                        id: row.id,
                        orgId: row.orgId
                    }
                });
            }
            // this.taskdata = row;
            // this.open = true;
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        ergoBookdicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    if (e.orgType == 2) {
                        // debugger
                        e.disabled = false

                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergoBookdicList(e.childrenList)
                    }
                })
            }
        },
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.custom /deep/ .el-radio {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    height: 250px;
    overflow: auto;
}

.custom /deep/ .el-radio__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 160px;
}

.custom /deep/ .el-radio__input {
    display: none;
}

.custom {
    /* height: calc(100vh - 500px); */
    /* overflow-y: auto; */
}
</style>