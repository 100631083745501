import request from "@/utils/request";


let urlPrefix = "/adminservice/note/book"

//增加、修改或删除标签
export const modifyTag =(data) =>{
    data.system = "group-notes"
    data.method = "modifyTag"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}
//查询标签
export const queryTags =(data) =>{
    data.system = "group-notes"
    data.method = "queryTags"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}

export const commentQueryPageComments =(data) =>{
    data.system = "group-notes"
    data.method = "queryPageComments"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}

export const deleteComment =(data) =>{
    data.system = "group-notes"
    data.method = "deleteComment"

   return request({
       url: "/adminservice/redcontroller/postData",
       method:"post",
       data: data
   })
}

// 查询内页点评信息
// export const commentQueryPageComments =(data) =>{
//     return request({
//         url: urlPrefix+"/queryPageComments",
//         method:"post",
//         data: data
//     })
// }

//添加评论
export const addComment =(data) =>{
    return request({
        url: urlPrefix+"/addComment",
        method:"post",
        data: data
    })
}

//优选
export const optimize =(data) =>{
    return request({
        url: urlPrefix+"/optimize",
        method:"get",
        params: data
    })
}

//取消优选
export const deOptimize =(data) =>{
    return request({
        url: urlPrefix+"/deOptimize",
        method:"get",
        params: data
    })
}

// 同意智能笔授权申请
export const penAuthApplyAgree =(query) =>{
    return request({
        url: "/adminservice/penAuthApply/agree",
        method:"get",
        params: query
    })
}