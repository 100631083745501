<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>展示管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!showId">新增展示</el-breadcrumb-item>
                <el-breadcrumb-item v-if="showId">编辑展示</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="展示名称" prop="showName">
                            <el-input v-model="form.showName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="展示地点" prop="showAddress">
                            <el-input v-model="form.showAddress" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="展示标题" prop="showTitle">
                            <el-input v-model="form.showTitle" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="展示副标题" prop="showSubTitle">
                            <el-input v-model="form.showSubTitle" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="展示介绍" prop="activityIntroduce">
                            <el-input :rows="5" v-model="form.activityIntroduce" type="textarea" placeholder="最多256个字"
                                maxlength="256" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!--用于查询本子，勾选本子-->

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="展示模式" prop="showType">
                            <el-radio-group v-model="form.showType">
                                <el-radio :label="item.type" v-for="(item, index) in showTypes">
                                    {{ item.name }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24" class="mr0" v-if="!role.includes(1)">
                        <el-form-item label="所属客户" class="w-100">
                            <el-select class="w-100" @change="changeCustom" v-model="queryParams.customId" clearable
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" class="mr0">
                        <el-form-item label="所属组织" class="w-100">
                            <el-tree-select class="w-100" ref="tsd" default-expand-all node-key="orgId" clearable
                                @change="changeOrg" v-model="queryParams.orgId" check-strictly :data="orgList"
                                placeholder="请选择组织" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" class="mr0" v-if="form.showType <= 2">
                        <el-form-item label="所属群组" class="w-100">
                            <el-select class="w-100" @change="changeQun" v-model="queryParams.qunId" clearable
                                placeholder="请选择群组">
                                <el-option v-for="item in qunList" :key="item.id" :label="(item.qunName)"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="form.showType <= 1">
                    <el-col :span="24" class="custom">
                        <el-form-item label="选择展示本" prop="bookId">
                            <el-radio-group v-model="form.bookId">
                                <el-radio :label="item.id" v-for="item in dataXinList">
                                    <div
                                        style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
                                        <img :title="item.book.bookName"
                                            style="width: 140px;margin-top: 10px;height: 180px;"
                                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div :title="item.book.bookName" style="width: 100px;" class="text-one mb10">{{
                                            item.book.bookName }}</div>
                                        <div :title="item.memberName" style="width: 100px;" class="text-one mb10">{{
                                            item.memberName }}</div>
                                    </div>
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { validatenull, isMobile } from '@/utils/validate'
import { teacherQueryOrRegUser } from "@/api/adminservice/classOrganizaMain"
import { zbhsignBookList, activityUpdate, activityInfo } from "@/api/adminservice/signIn"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo } from "@/api/adminservice/classOrganizaMain"
import { createBookShow, infoBookShow, updateBookShow } from "@/api/groupservice/bookShow"
import { noteotherBooks, notepageData, noteConditionBooks, noteBookQunBookList } from "@/api/adminservice/book";

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            showTypes: [
                {
                    type: 1,
                    name: "班级展示",
                },
                {
                    type: 2,
                    name: "群组展示",
                },
                {
                    type: 3,
                    name: "组织展示",
                }
            ],
            signBookKist: [],
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            showId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                showName: '',
                showAddress: '',
                showTitle: '',
                showSubTitle: '',
                customId: '',
                memberList: [],
                bookId: '',
                sortType: 0,
                showType: 1,//展示模式，默认班级本展示
                customId: '',
                orgId: '',
                qunId: '',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                sortType: [
                    { required: true, message: "请选择排序类型", trigger: "change" },
                ],
                showName: [
                    { required: true, message: "请输入展示名称", trigger: "blur" },
                ],
                showTitle: [
                    { required: true, message: "请输入展示标题", trigger: "blur" },
                ],
                customId: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                // orgId: [
                //     { required: true, message: "请选择客户", trigger: "change" },
                // ],
                // bookId: [
                //     { required: true, message: "请选择展示签到本", trigger: "change" },
                // ],
                // memberList: [
                //     { required: true, validator: this.checkOrgValidator }
                // ]
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            tableData: [],
            role: '',
            // 查询参数
            queryParams: {
                title: '',
                qzId: '',
                teacherId: '',
                customId: '',
                qunId: '',
                orgId: '',
                keywords: '',
                writeTime: [],
                sortType: 1,
                qunRecordId: ''
            },
            qunList: [],
            orgList: [],
            dataXinList: [],
            dataList: [],
            noteBook: [],
            updateFlag: false,//true修改，false新增
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()

        this.role = this.$storage.get("role")
        if (this.$storage.get("role").includes(1)) {
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        }
        this.getCustoms();


    },
    mounted() {
        // this.getList()

        if (this.$route.query.showId) {
            this.showId = this.$route.query.showId;
            this.updateFlag = true;
            this.getInfo()
        } else {
            if (this.$storage.get("role").includes(1)) {
                this.form.customId = this.$storage.get("customId")
                this.changeCustom()
            }
        }
    },
    computed: {
    },
    methods: {
        getZhktQueryDict() {
            zbhsignBookList().then(res => {
                this.signBookKist = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            infoBookShow({ showId: this.showId }).then(res => {
                this.form = res.data.data;
                this.queryParams.customId = this.form.customId
                this.queryParams.orgId = this.form.orgId
                this.queryParams.qunId = this.form.qunId

                this.changeCustom();

                // this.tableData = this.form.memberList
            })
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.tableData.splice(index, 1)
                // this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                username: '',
                memberName: '',
                userId: '',
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            if (this.tableData.length == 0) {
                this.$message.error('请填写授权用户')
                result = false
            }
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.memberAccount == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateUsername(value,) {
            if (!isMobile(value.username)) {
                this.$message.error("手机号码格式不正确")
            }
            teacherQueryOrRegUser({ username: value.username, trueName: value.memberName }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.trueName
                    }
                    value.userId = res.data.data.userId
                    // callback();
                }
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        // changeCustom() {
        //     this.formList = [];
        //     this.orgList = [];
        //     queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
        //         this.orgList = res.data.data;
        //         res.data.data.forEach((e, i) => {
        //             e.label = e.orgName
        //             e.value = e.orgId
        //             e.children = e.childrenList;
        //             e.disabled = true
        //             this.ergodicList(e.childrenList)
        //         })
        //         // console.log(res.data.data)
        //         this.orgList = res.data.data;
        //     })
        // },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        getCustoms() {
            this.customList = [];
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('070');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        //只拉取图书角客户 070
                        this.customList.push(e)

                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        //只拉取图书角客户 070
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e, i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i, 1)
                        this.customList.unshift(e)
                    }
                })
                // console.log(this.customList);
            });
        },
        changeCustom() {

            // console.log("changeCustom")

            this.qunIdList = [];
            this.qunBookList = [];
            this.orgList = [];
            this.queryParams.qunRecordId = '';
            if (!this.updateFlag) { //非更新状态下
                this.queryParams.orgId = '';
                this.queryParams.qunId = '';
            }
            this.qunList = [];
            queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                res.data.data.forEach(e => {
                    this.qunIdList.push(e.id)
                })
                this.getTreeCustom()
                // this.getList()
            })
        },
        getTreeCustom() {
            queryTreeCustomOrg({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;

                if (this.updateFlag) {
                    this.changeOrg();
                }
            })
        },
        changeOrg() {
            this.qunBookList = [];
            this.queryParams.qunRecordId = '';
            this.qunIdList = [];
            if (!this.updateFlag) { //非更新状态下
                // this.queryParams.orgId = '';
                this.queryParams.qunId = '';
            }
            // this.queryParams.qunId = ''
            queryCustomQun({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }

                res.data.data = res.data.data.filter(itemA => {
                    return itemA.groupType == 1
                })

                this.qunList = [];

                res.data.data.forEach(e => {
                    if (e.isStop == 0) { //先启用
                        this.qunList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.isStop == 1) { //停用
                        e.qunName = e.qunName + "【停用】"
                        this.qunList.push(e)
                    }
                })


                // this.qunList = res.data.data;
                if (this.updateFlag) {
                    this.changeQun()
                }
                // console.log(this.qunList);
            })
            // this.getList()
        },
        changeQun() {
            this.qunBookList = [];
            this.queryParams.qunRecordId = '';
            if (this.queryParams.qunId) {
                noteBookQunBookList({ qunId: this.queryParams.qunId }).then(res => {
                    this.qunBookList = res.data.data;
                })
            }
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            if (process.env.NODE_ENV != 'development') {

                if (!this.queryParams.customId) {
                    this.$message.warning("请选择客户")
                    return;
                }
                if (!this.queryParams.orgId) {
                    this.$message.warning("请选择组织")
                    return;
                }
                if (!this.queryParams.qunId) {
                    this.$message.warning("请选择群组")
                    return;
                }
            }
            this.dataXinList = [];
            if (this.queryParams.writeTime && this.queryParams.writeTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.writeTime[0]
                this.queryParams.endTime = this.queryParams.writeTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }

            this.loading = false;
            this.isShow = false;
            this.dataList = []
            this.noteBook = []
            var obj = {}
            noteConditionBooks(this.queryParams).then(res => {
                // console.log(res);
                this.dataXinList = res.data.data;
            })
        },

        /** 提交按钮 */
        submitForm() {
            this.form.customId = this.queryParams.customId;
            this.form.orgId = this.queryParams.orgId;
            this.form.qunId = this.queryParams.qunId;
            this.form.userId = JSON.parse(this.$storage.get('userInfo')).userId

            if (this.form.showType <= 3) {
                if (!this.form.orgId) {
                    this.$message.warning("请选择组织")
                    return;
                }
            }

            if (this.form.showType <= 2) {
                if (!this.form.qunId) {
                    this.$message.warning("请选择群")
                    return;
                }
            }

            if (this.form.showType <= 1) {
                if (!this.form.bookId) {
                    this.$message.warning("请选择本子")
                    return;
                }
            }
            this.$refs["form"].validate(valid => {
                if (valid) {
                    // console.log(this.form)
                    this.form.memberList = this.tableData
                    if (this.$route.query.showId) {
                        updateBookShow(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/bookShow', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        createBookShow(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/bookShow', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/bookShow')
            // this.$emit('isTg', true)
        },
        // ergodicList(childrenList) {
        //     if (childrenList.length != 0) {
        //         childrenList.forEach(e => {
        //             e.label = e.orgName
        //             e.value = e.orgId
        //             // console.log(this.findParentNode(e.orgId))
        //             if (this.findParentNode(e.orgId).indexOf(2) == -1) {
        //                 e.disabled = true
        //             }
        //             if (e.orgType == 5) {
        //                 // debugger
        //                 e.disabled = true
        //                 console.log(e)
        //             }
        //             if (e.childrenList.length != 0) {
        //                 e.children = e.childrenList
        //                 this.ergodicList(e.childrenList)
        //             }
        //         })
        //     }
        // },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId

                    // console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        // console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 200px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* max-height: 250px;
    overflow-y: auto; */
}

.custom /deep/ .el-radio {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-radio__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
</style>