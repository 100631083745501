import request from "@/utils/request";

// 拒绝智能笔授权申请
export const penAuthApplyRefuse =(data) =>{
    return request({
        url: "/adminservice/penAuthApply/refuse",
        method:"post",
        data: data
    })
}

// 同意智能笔授权申请
export const penAuthApplyAgree =(query) =>{
    return request({
        url: "/adminservice/penAuthApply/agree",
        method:"get",
        params: query
    })
}

// 查询智能笔授权申请列表
export const penAuthApplyPageList =(query) =>{
    return request({
        url: "/adminservice/penAuthApply/pageList",
        method:"get",
        params: query
    })
}

// 删除智能笔
export const penDelete =(query) =>{
    return request({
        url: "/adminservice/pen/delete",
        method:"get",
        params: query
    })
}

// 分页获取智能笔信息列表
export const penList =(query) =>{
    return request({
        url: "/adminservice/pen/list",
        method:"get",
        params: query
    })
}

// 修改智能笔信息
export const penUpdate =(data) =>{
    return request({
        url: "/adminservice/pen/update",
        method:"post",
        data: data
    })
}

// 新增智能笔信息
export const addPen =(data) =>{
    data.system = "system"
    data.method = "addPen"

   return request({
       url: "/adminservice/redcontroller/postData"+"?method="+data.method,
       method:"post",
       data: data
   })
}