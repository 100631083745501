<template>
  <div id="home-box">
    <el-container>
      <!--顶部菜单-->
      <el-header style="background: #21ac74;">
        <el-row type="flex" align="middle">
          <!--左侧组织切换-->
          <el-col :span="20">
            <div class="disflex-al-ct">
              <div class="zbh-logo" :style="isActive ? 'width:64px;' : 'width:250px;'">
                <img src="../assets/zbhlogo-w.png" alt="">
                <div :title="'纸笔智能服务平台'" class="text-one"
                  style="color: #fff;width: 160px;overflow: hidden;text-align: left;font-family:hfsh;font-size: 20px;">
                  纸笔智能服务平台</div>
              </div>
              <!-- <el-dropdown @command="changeGroup" trigger="click">
                <el-button type="primary">
                  {{ account.groupName }}
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, index) in accountList" :command="item" :key="index">
                      {{ item.groupName }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
</el-dropdown> -->
              <!-- <div class="" @click="toggleClick">
                <svg :class="{ 'is-active': isActive }" class="hamburger" viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg" width="64" height="64">
                  <path fill="#fff"
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                </svg>
              </div> -->

              <!--顶部模块-->
              <div style="flex: 1;" class="top-tp">
                <el-menu class="el-menu-demo" mode="horizontal" background-color="transparent"
                  active-background-color="#fff" :default-active="activePath" text-color="#fff" active-text-color="#fff"
                  @select="handleSelect">
                  <el-menu-item index="systemManage" @click="saveNavState('systemManage')" v-if="role.includes(0)">
                    系统管理</el-menu-item>
                  <el-menu-item index="formManage" @click="saveNavState('formManage')"
                    v-if="role.includes(0) || (role.includes(1) && customTypeList.includes('010'))">
                    {{ objName['010'] ? objName['010'].moduleName : '表单' }}</el-menu-item>
                  <el-menu-item v-if="!role.includes(1)" index="smartClassManage"
                    @click="saveNavState('smartClassManage')">
                    {{ objName['020'] ? objName['020'].moduleName : '云课堂' }}</el-menu-item>
                  <el-menu-item v-if="(role.includes(1) && customTypeList.includes('020'))" index="smartClassManage"
                    @click="saveNavState('smartClassManage')">
                    {{ objName['020'] ? objName['020'].moduleName : '云课堂' }}</el-menu-item>
                  <el-menu-item index="customerManage" @click="saveNavState('customerManage')"
                    v-if="role.includes(0) || (role.includes(1) && customTypeList.includes('030'))">
                    {{ objName['030'] ? objName['030'].moduleName : '群笔记' }}</el-menu-item>
                  <el-menu-item v-if="!role.includes(1)" index="smartReviewManage"
                    @click="saveNavState('smartReviewManage')">
                    {{ objName['040'] ? objName['040'].moduleName : '云批阅' }}</el-menu-item>
                  <el-menu-item v-if="(role.includes(1) && customTypeList.includes('040'))" index="smartReviewManage"
                    @click="saveNavState('smartReviewManage')">
                    {{ objName['040'] ? objName['040'].moduleName : '云批阅' }}</el-menu-item>
                  <el-menu-item index="postcardManage" @click="saveNavState('postcardManage')"
                    v-if="role.includes(0) || (role.includes(1) && customTypeList.includes('050'))">
                    {{ objName['050'] ? objName['050'].moduleName : '明信片' }}</el-menu-item>
                  <el-menu-item index="smartCheck" @click="saveNavState('smartCheck')"
                    v-if="role.includes(0) || (role.includes(1) && customTypeList.includes('060'))">
                    {{ objName['060'] ? objName['060'].moduleName : '签到' }}</el-menu-item>
                  <el-menu-item index="library" @click="saveNavState('library')"
                    v-if="role.includes(0) || (role.includes(1) && customTypeList.includes('070'))">
                    {{ objName['070'] ? objName['070'].moduleName : '图书角' }}
                  </el-menu-item>
                </el-menu>
              </div>
            </div>
          </el-col>
          <!--个人中心及消息通知-->
          <el-col :span="4">
            <el-row align="middle">
              <el-col :span="18">
                <div style="float: right;" v-if="role.includes(1)">
                  <el-dropdown>
                    <span class="el-dropdown-link cursor" style="color: #fff;white-space: nowrap;">
                      {{ customInfo.customName }}
                      <el-icon class="el-icon--right" style="transform: translateY(2px);">
                        <arrow-down />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="chooseCustom(item)" v-for="item in customList">{{ item.customName
                          }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </el-col>
              <el-col :span="6" :xl="4">
                <el-dropdown class="cursor">
                  <el-avatar :size="35" :src="userHeadImg" />
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div style="text-align: center;">{{ popname }}</div>
                      <el-dropdown-item @click="logout">安全退出</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-header>
      <tags-view />


      <el-container>
        <el-aside class="lftop" :style="isActive ? 'width:64px;' : 'width: 200px;'">

          <!--左侧菜单-->
          <el-menu :collapse-transition="false" :collapse="isActive" unique-opened :default-active="activeSecondPath"
            active-text-color="#005937" active-background-color="#54d8a5" class="el-menu-vertical-demo"
            background-color="#fff" text-color="#999999" router @open="handleOpen" @close="handleClose">
            <el-menu-item v-for="item in menusList[activePath]" :index="item.path"
              @click="saveSecondNavState(item.path)">
              <span>{{ item.title }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!--侧边栏-->


        <!--展示区-->
        <el-main>
          <keep-alive v-if="proEnv == 'development'">
            <router-view></router-view>
          </keep-alive>
          <router-view v-slot="{ Component, route }" v-else>
            <keep-alive :include="cachedViews">
              <component :key="route.name" :is="Component" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :key="route.name" :is="Component" v-if="!$route.meta.keepAlive" />
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :title="'选择校区'" v-model="showChangeCampus" width="40%">
      <el-radio v-model="schoolId" v-for="item in schoolList" :key="item.schoolId" :label="Number(item.schoolId)"
        border>{{
          item.schoolName }}</el-radio>
      <template #footer>
        <div class="disflex-row-center">
          <el-button type="primary" @click="changeSchool">确 定</el-button>
          <el-button @click="showChangeCampus = false">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import global from "@/utils/global"
import { menuList } from "@/api/admin/menu"
import Screenfull from '@/components/Screenfull'
import Breadcrumb from '@/components/Breadcrumb'
import { querySchoolList } from "@/api/user/login";
import TagsView from '@/components/TagsView/index.vue'
import { ElLoading } from 'element-plus'
import { moduleList } from "@/api/adminservice/custom";

export default {
  components: {
    Screenfull,
    Breadcrumb,
    TagsView
  },
  name: "groupHome",
  data() {
    return {
      schoolId: '',
      moduleList: [],
      schoolList: [],
      customList: [],
      showChangeCampus: false,
      activePath: "",
      activeSecondPath: "",
      userHeadImg: "",
      userInfo: {},
      objName: {},
      menusList: {
        customerManage: [
          { title: '组织结构', path: 'arrayMaintenance', icon: 'noteBook' },
          { title: '群组管理', path: 'groupSet', icon: 'messageBox' },
          { title: '查看群笔记', path: 'noteOtherBooks', icon: 'messageBox' },
          { title: '笔记历史', path: 'writeHistory', icon: 'messageBox' },
          { title: '任务管理', path: 'noteTask', icon: 'messageBox' },
          { title: '待办任务', path: 'naskToDo', icon: 'messageBox' },
        ],
        formManage: [
          { title: '场景管理', path: 'scenarioMain', icon: 'menu' },
          { title: '表单管理', path: 'formMain', icon: 'noteBook' },
          { title: '表单记录查询', path: 'formRecord', icon: 'messageBox' },
          { title: '表单记录校审', path: 'formTTTRecordProofreading', icon: 'messageBox' },
          { title: '表单数据查询', path: 'formDataQuery', icon: 'messageBox' },
        ],
        systemManage: [
          { title: '客户管理', path: 'customerManage', icon: 'menu' },
        ]
      },
      accountList: [],
      account: {},
      global: global,
      logoTextWidth: 200,   // 左上角logo文字宽度
      isActive: false,      // 是否菜单折叠
      isAuth: this.$storage.get('isAuth'),
      isSuperAuth: this.$storage.get('isSuperAuth'),
      issuedBookAuth: this.$storage.get('issuedBookAuth'),
      popname: '',
      schoolName: '',
      role: '',
      proEnv: 'production',
      customTypeList: [],
      customInfo: {}
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
  },
  watch: {

  },
  created() {
    this.getModuleList();
    // this.proEnv = process.env.NODE_ENV;

    this.role = this.$storage.get("role")
    if (this.$storage.get("customList") && this.$storage.get("customList") != 'undefined') {
      this.customList = JSON.parse(this.$storage.get("customList"))
    }
    if (this.$storage.get("custom") && this.$storage.get("custom") != 'undefined') {
      this.customInfo = JSON.parse(this.$storage.get("custom"))
    }

    console.log(this.customInfo);
    if (this.customInfo) {
      this.customTypeList = this.customInfo.customType && this.customInfo.customType.split(',')
    }

    console.log(1111, this.role);
    if (this.role.includes(0)) {
      this.menusList = {
        systemManage: [
          { title: '业务模块', path: 'businessModule', icon: 'menu' },
          { title: '管理员管理', path: 'platformAdmin', icon: 'menu' },
          { title: '客户管理', path: 'customerManage', icon: 'menu' },
          { title: '智能笔管理', path: 'penMenage', icon: 'menu' },
          { title: '智能笔授权管理', path: 'penAuthApply', icon: 'menu' },
          { title: '智能本管理', path: 'smartBookManage', icon: 'menu' },
          { title: '更新内容管理', path: 'editManage', icon: 'menu' }
        ],
        customerManage: [
          { title: '组织结构', path: 'arrayMaintenance', icon: 'noteBook' },
          { title: '群组管理', path: 'groupSet', icon: 'messageBox' },
          { title: '查看群笔记', path: 'noteOtherBooks', icon: 'messageBox' },
          { title: '笔记历史', path: 'writeHistory', icon: 'messageBox' },
          { title: '任务管理', path: 'noteTask', icon: 'messageBox' },
          { title: '待办任务', path: 'naskToDo', icon: 'messageBox' },
        ],
        formManage: [
          { title: '场景管理', path: 'scenarioMain', icon: 'menu' },
          { title: '表单管理', path: 'formMain', icon: 'noteBook' },
          { title: '表单记录查询', path: 'formRecord', icon: 'messageBox' },
          { title: '表单记录校审', path: 'formTTTRecordProofreading', icon: 'messageBox' },
          { title: '表单数据查询', path: 'formDataQuery', icon: 'messageBox' },
        ],
        postcardManage: [
          { title: '明信片管理', path: 'cardMain', icon: 'menu' },
          { title: '场景管理', path: 'postScenarioMain', icon: 'menu' },
          { title: '明信片记录查询', path: 'postoncardRecordQuery', icon: 'menu' },
        ],
        smartCheck: [
          // { title: '教辅章节批阅报告', path: 'ChapterReport', icon: 'noteBook' },
          { title: '活动管理', path: 'eventMain', icon: 'noteBook' },
        ],
        library: [
          // { title: '学校维护', path: 'libraryClassOrganizaMain', icon: 'noteBook' },
          // { title: '组织结构', path: 'libraryArrayMaintenance', icon: 'noteBook' },
          // { title: '班级管理', path: 'libraryClassManagement', icon: 'noteBook' },
          { title: '图书角管理', path: 'libraryGroupSet', icon: 'noteBook' },
          { title: '图书角阅读笔记', path: 'libraryNoteOtherBooks', icon: 'messageBox' },
          { title: '阅读笔记展示', path: 'bookShow', icon: 'messageBox' },
        ],
        smartClassManage: [
          { title: '学校维护', path: 'classOrganizaMain', icon: 'noteBook' },
          { title: '教师维护', path: 'teacherMain', icon: 'noteBook' },
          { title: '学生维护', path: 'studentMain', icon: 'noteBook' },
          { title: '奖评设置', path: 'commentSet', icon: 'noteBook' },
          { title: '班级管理', path: 'classManagement', icon: 'noteBook' },
          { title: '备课管理', path: 'lessPreparatManage', icon: 'noteBook' },
          { title: '课堂记录查看', path: 'classRecords', icon: 'menu' },
        ],
        smartReviewManage: [
          { title: '教辅维护', path: 'teachAssistantMain', icon: 'noteBook' },
          { title: '教辅结构维护', path: 'teachingAidsStructure', icon: 'noteBook' },
          { title: '班级错题报告', path: 'classErrorReport', icon: 'noteBook' },
          { title: '班级学生报告', path: 'classStudentReport', icon: 'noteBook' },
          { title: '班级批阅对比统计', path: 'classGrading', icon: 'noteBook' },
          { title: '班级错题对比统计', path: 'classIncorrectAnswers', icon: 'noteBook' },
          { title: '教辅章节批阅报告', path: 'ChapterReport', icon: 'noteBook' },
        ],
      }
      if (this.$storage.get("isPlatform") == 1) {
        this.menusList['systemManage'].shift();
        this.menusList['systemManage'].shift();
        this.menusList['systemManage'].pop();
      }
    } else {
      if (this.role.includes(1)) {
        var menusList = {
          formManage: [
            { title: '场景管理', path: 'scenarioMain', icon: 'menu' },
            { title: '表单管理', path: 'formMain', icon: 'noteBook' },
            { title: '表单记录查询', path: 'formRecord', icon: 'messageBox' },
            { title: '表单记录校审', path: 'formTTTRecordProofreading', icon: 'messageBox' },
            { title: '表单数据查询', path: 'formDataQuery', icon: 'messageBox' },
          ],
          smartReviewManage: [
            { title: '教辅维护', path: 'teachAssistantMain', icon: 'noteBook' },
            { title: '教辅结构维护', path: 'teachingAidsStructure', icon: 'noteBook' },
            { title: '班级错题报告', path: 'classErrorReport', icon: 'noteBook' },
            { title: '班级学生报告', path: 'classStudentReport', icon: 'noteBook' },
            { title: '班级批阅对比统计', path: 'classGrading', icon: 'noteBook' },
            { title: '班级错题对比统计', path: 'classIncorrectAnswers', icon: 'noteBook' },
            { title: '教辅章节批阅报告', path: 'ChapterReport', icon: 'noteBook' },
          ],
          smartClassManage: [
            { title: '学校维护', path: 'classOrganizaMain', icon: 'noteBook' },
            { title: '教师维护', path: 'teacherMain', icon: 'noteBook' },
            { title: '学生维护', path: 'studentMain', icon: 'noteBook' },
            { title: '奖评设置', path: 'commentSet', icon: 'noteBook' },
            { title: '班级管理', path: 'classManagement', icon: 'noteBook' },
            { title: '备课管理', path: 'lessPreparatManage', icon: 'noteBook' },
            { title: '课堂记录查看', path: 'classRecords', icon: 'menu' },
          ],
          customerManage: [
            { title: '组织结构', path: 'arrayMaintenance', icon: 'noteBook' },
            { title: '群组管理', path: 'groupSet', icon: 'messageBox' },
            { title: '查看群笔记', path: 'noteOtherBooks', icon: 'messageBox' },
            { title: '笔记历史', path: 'writeHistory', icon: 'messageBox' },
            { title: '任务管理', path: 'noteTask', icon: 'messageBox' },
            { title: '待办任务', path: 'naskToDo', icon: 'messageBox' },
          ],
          postcardManage: [
            { title: '场景管理', path: 'postScenarioMain', icon: 'menu' },
            { title: '明信片记录查询', path: 'postoncardRecordQuery', icon: 'menu' },
          ],
          smartCheck: [
            { title: '活动管理', path: 'eventMain', icon: 'noteBook' }
          ],
          library: [
            { title: '图书角管理', path: 'libraryGroupSet', icon: 'noteBook' },
            { title: '图书角阅读笔记', path: 'libraryNoteOtherBooks', icon: 'messageBox' },
            { title: '阅读笔记展示', path: 'bookShow', icon: 'messageBox' },
          ],
        }
        if (this.customTypeList && this.customTypeList.length != 0) {
          const sortedKeys = Object.keys(menusList);
          if (this.customTypeList[0] == '010') {
            this.menusList = {
              formManage: [
                { title: '场景管理', path: 'scenarioMain', icon: 'menu' },
                { title: '表单管理', path: 'formMain', icon: 'noteBook' },
                { title: '表单记录查询', path: 'formRecord', icon: 'messageBox' },
                { title: '表单记录校审', path: 'formTTTRecordProofreading', icon: 'messageBox' },
                { title: '表单数据查询', path: 'formDataQuery', icon: 'messageBox' },
              ],
            }
          } else if (this.customTypeList[0] == '020') {
            this.menusList = {
              smartClassManage: [
                { title: '学校维护', path: 'classOrganizaMain', icon: 'noteBook' },
                { title: '教师维护', path: 'teacherMain', icon: 'noteBook' },
                { title: '学生维护', path: 'studentMain', icon: 'noteBook' },
                { title: '奖评设置', path: 'commentSet', icon: 'noteBook' },
                { title: '班级管理', path: 'classManagement', icon: 'noteBook' },
                { title: '备课管理', path: 'lessPreparatManage', icon: 'noteBook' },
                { title: '课堂记录查看', path: 'classRecords', icon: 'menu' },
              ],
            }
          } else if (this.customTypeList[0] == '030') {
            this.menusList = {
              customerManage: [
                { title: '组织结构', path: 'arrayMaintenance', icon: 'noteBook' },
                { title: '群组管理', path: 'groupSet', icon: 'messageBox' },
                { title: '查看群笔记', path: 'noteOtherBooks', icon: 'messageBox' },
                { title: '笔记历史', path: 'writeHistory', icon: 'messageBox' },
                { title: '任务管理', path: 'noteTask', icon: 'messageBox' },
                { title: '待办任务', path: 'naskToDo', icon: 'messageBox' },
              ],
            }
          } else if (this.customTypeList[0] == '040') {
            this.menusList = {
              smartReviewManage: [
                { title: '教辅维护', path: 'teachAssistantMain', icon: 'noteBook' },
                { title: '教辅结构维护', path: 'teachingAidsStructure', icon: 'noteBook' },
                { title: '班级错题报告', path: 'classErrorReport', icon: 'noteBook' },
                { title: '班级学生报告', path: 'classStudentReport', icon: 'noteBook' },
                { title: '班级批阅对比统计', path: 'classGrading', icon: 'noteBook' },
                { title: '班级错题对比统计', path: 'classIncorrectAnswers', icon: 'noteBook' },
                { title: '教辅章节批阅报告', path: 'ChapterReport', icon: 'noteBook' },
              ],
            }
          } else if (this.customTypeList[0] == '050') {
            this.menusList = {
              postcardManage: [
                { title: '场景管理', path: 'postScenarioMain', icon: 'menu' },
                { title: '明信片记录查询', path: 'postoncardRecordQuery', icon: 'menu' },
              ],
            }
          } else if (this.customTypeList[0] == '060') {
            this.menusList = {
              smartCheck: [
                { title: '活动管理', path: 'eventMain', icon: 'noteBook' }
              ],
            }
          } else if(this.customTypeList[0] == '070') {
            this.menusList = {
              library: [
                { title: '图书角管理', path: 'libraryGroupSet', icon: 'noteBook' },
                { title: '图书角阅读笔记', path: 'libraryNoteOtherBooks', icon: 'messageBox' },
                { title: '阅读笔记展示', path: 'bookShow', icon: 'messageBox' },
              ],
            }
          }
          sortedKeys.forEach(key => {
            this.menusList[key] = menusList[key]
          })
        } else {
          this.menusList = menusList;
        }
        console.log(this.customTypeList);
      } else {
        this.menusList = {
          // smartCheck: [
          //   { title: '活动管理', path: 'eventMain', icon: 'noteBook' }
          // ],
          // formManage: [
          //   { title: '场景管理', path: 'scenarioMain', icon: 'menu' },
          //   { title: '表单管理', path: 'formMain', icon: 'noteBook' },
          //   { title: '表单记录查询', path: 'formRecord', icon: 'messageBox' },
          //   { title: '表单记录校审', path: 'formTTTRecordProofreading', icon: 'messageBox' },
          //   { title: '表单数据查询', path: 'formDataQuery', icon: 'messageBox' },
          // ],
          // postcardManage: [
          //   // { title: '明信片管理', path: 'cardMain', icon: 'menu' },
          //   { title: '场景管理', path: 'postScenarioMain', icon: 'menu' },
          //   { title: '明信片记录查询', path: 'postcardRecordQuery', icon: 'menu' },
          // ],
          smartClassManage: [
            // { title: '学校维护', path: 'classOrganizaMain', icon: 'noteBook' },
            { title: '奖评设置', path: 'commentSet', icon: 'noteBook' },
            { title: '班级管理', path: 'classManagement', icon: 'noteBook' },
            // { title: '教师维护', path: 'teacherMain', icon: 'noteBook' },
            // { title: '学生维护', path: 'studentMain', icon: 'noteBook' },
            { title: '备课管理', path: 'lessPreparatManage', icon: 'noteBook' },
            { title: '课堂记录查看', path: 'classRecords', icon: 'menu' },
          ],
          smartReviewManage: [
            //   { title: '教辅维护', path: 'teachAssistantMain', icon: 'noteBook' },
            //   { title: '教辅结构维护', path: 'teachingAidsStructure', icon: 'noteBook' }
            { title: '班级错题报告', path: 'classErrorReport', icon: 'noteBook' },
            { title: '班级学生报告', path: 'classStudentReport', icon: 'noteBook' },
            { title: '班级批阅对比统计', path: 'classGrading', icon: 'noteBook' },
            { title: '班级错题对比统计', path: 'classIncorrectAnswers', icon: 'noteBook' },
            { title: '教辅章节批阅报告', path: 'ChapterReport', icon: 'noteBook' },
          ],
        }
        // if (this.role.includes(5)) {
        //   this.menusList.smartReviewManage.push({ title: '教辅维护', path: 'teachAssistantMain', icon: 'noteBook' })
        //   this.menusList.smartReviewManage.push({ title: '教辅结构维护', path: 'teachingAidsStructure', icon: 'noteBook' })
        // }
        if (this.role.includes(2)) {
          this.menusList.smartClassManage.splice(1, 0, { title: '教师维护', path: 'teacherMain', icon: 'noteBook' })
          this.menusList.smartClassManage.splice(1, 0, { title: '学生维护', path: 'studentMain', icon: 'noteBook' })

        } else {
          if (this.role.includes(4)) {
            this.menusList.smartClassManage.splice(1, 0, { title: '学生维护', path: 'studentMain', icon: 'noteBook' })
          }
        }

      }
    }


    this.logoTextWidth = window.innerWidth * (20 / 100);
    this.watchWidth();
    // if (window.sessionStorage.getItem('activePath')) {
    //   this.activePath = window.sessionStorage.getItem('activePath')
    // } else {
    console.log(Object.keys(this.menusList))
    this.activePath = Object.keys(this.menusList)[0]
    window.sessionStorage.setItem('activePath', Object.keys(this.menusList)[0])
    // }
    console.log(1)
    // if (window.sessionStorage.getItem('activeSecondPath')) {

    this.activeSecondPath = window.sessionStorage.getItem('activeSecondPath')
    console.log(222222, this.activeSecondPath)

    // } else {
    console.log(Object.keys(this.menusList))
    this.activeSecondPath = this.menusList[Object.keys(this.menusList)[0]][0].path
    window.sessionStorage.setItem('activeSecondPath', this.menusList[Object.keys(this.menusList)[0]][0].path)
    // }

    this.replace('/' + this.activeSecondPath)
    this.popname = JSON.parse(this.$storage.get("userInfo")).username
    this.schoolName = this.$storage.get("schoolName")
    this.userInfo = JSON.parse(this.$storage.get("userInfo"))
    // this.accountList = JSON.parse(this.$storage.get("groupAccounts"))
    // this.account = JSON.parse(this.$storage.get("lastGroupAccount"))
    // this.userHeadImg = this.global.fileUrl + "/user/head/" + this.userInfo.userId + ".png_original?" + new Date().getTime()
    // this.listMenu()
  },
  mounted() {
    // 监听网页大小
    window.onresize = () => {
      return (() => {
        this.watchWidth();
        console.log(window.innerWidth)
        this.logoTextWidth = window.innerWidth * (20 / 100);
      })();
    };
  },
  methods: {
    getModuleList() {
      moduleList().then(res => {
        this.moduleList = res.data.data;
        this.moduleList.forEach(e => {
          this.objName[e.moduleNo] = e;
        })
      });
    },
    chooseCustom(item) {
      ElLoading.service({
        lock: true,
        text: '切换中',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$storage.set("custom", JSON.stringify(item));
      this.$storage.set("customId", item.customId);
      if (item.moduleList) {
        item.moduleList.forEach(e => {
          if (e.moduleNo == '010') {
            this.$storage.set("010StTime", e.authTime);
            this.$storage.set("010EdTime", e.endTime);
          } else if (e.moduleNo == '020') {
            this.$storage.set("020StTime", e.authTime);
            this.$storage.set("020EdTime", e.endTime);
          } else if (e.moduleNo == '030') {
            this.$storage.set("030StTime", e.authTime);
            this.$storage.set("030EdTime", e.endTime);
          } else if (e.moduleNo == '040') {
            this.$storage.set("040StTime", e.authTime);
            this.$storage.set("040EdTime", e.endTime);
          } else if (e.moduleNo == '050') {
            this.$storage.set("050StTime", e.authTime);
            this.$storage.set("050EdTime", e.endTime);
          } else if (e.moduleNo == '060') {
            this.$storage.set("060StTime", e.authTime);
            this.$storage.set("060EdTime", e.endTime);
          }
          else if (e.moduleNo == '070') {
            this.$storage.set("070StTime", e.authTime);
            this.$storage.set("070EdTime", e.endTime);
          }
        })
      }
      if (item.moduleList[0].moduleNo == '010') {
        this.$router.push({ path: "/scenarioMain" });
      } else if (item.moduleList[0].moduleNo == '020') {
        this.$router.push({ path: "/classOrganizaMain" });
      } else if (item.moduleList[0].moduleNo == '030') {
        this.$router.push({ path: "/arrayMaintenance" });
      } else if (item.moduleList[0].moduleNo == '040') {
        this.$router.push({ path: "/teachAssistantMain" });
      } else if (item.moduleList[0].moduleNo == '050') {
        this.$router.push({ path: "/postScenarioMain" });
      } else if (item.moduleList[0].moduleNo == '060') {
        this.$router.push({ path: "/eventMain" });
      }

      setTimeout(() => {
        location.reload();
      })

      console.log(item);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    changeshowChangeCampus() {
      querySchoolList().then(res => {
        this.schoolList = res.data.data;
        this.schoolId = Number(this.$storage.get("schoolId"))
        this.showChangeCampus = true;
      })
    },
    changeSchool() {
      this.schoolList.forEach(e => {
        if (e.schoolId == this.schoolId) {
          this.$storage.set('schoolName', e.schoolName);
          this.$storage.set('schoolId', e.schoolId);
        }
      })
      this.showChangeCampus = false;
      console.log(this.schoolId)
    },
    // 监听网页大小
    watchWidth() {
      if (window.innerWidth < 1000) {
        this.isActive = true;
        this.$store.commit("zdy/setIsActive", true);
      } else {
        this.isActive = false;
        this.$store.commit("zdy/setIsActive", false);
      }
    },
    toggleClick() {
      setTimeout(() => {
        this.isActive = !this.isActive;
        this.$store.commit("zdy/setIsActive", this.isActive);
        console.log(this.$store.state.zdy.isActive)
      }, 10)

    },
    listMenu() {
      menuList().then(res => {
        if (res.data.code === 1) {
          this.menusList = res.data.data
          this.menusList.push({
            icon: "iconfont icon-201yonghu_yonghu2",
            id: "1e8fabae1b914eb2bcf7c1752c8888",
            name: "demo",
            parentId: "3",
            path: "/",
            sort: 3,
            children: [{
              children: [],
              icon: "iconfont icon-yonghuzhongxin-woyouxuqiu",
              id: "453fad7aecac49029cce7787c7bc1e33",
              name: "demo",
              parentId: "1e8fabae1b914eb2bcf7c1752c40a6b7",
              path: "/demo",
              sort: 1,
            }]
          }
          )
          console.log(this.menusList)
        } else {
          this.$message.error("菜单加载失败")
        }
      })
    },
    handleOpen: function () { },
    handleClose: function () { },
    changeGroup(account) {
      this.account = account
      this.$storage.set("lastGroupAccount", JSON.stringify(account));
      this.$addStorageEvent("saasGroup", account);
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      this.$router.push({ path: '/' + this.menusList[this.activePath][0].path });
      this.saveSecondNavState(this.menusList[this.activePath][0].path)
    },
    saveSecondNavState(activePath) {
      window.sessionStorage.setItem('activeSecondPath', activePath)
      this.activeSecondPath = activePath
    },
    logout() {
      this.$confirm("您正在退出系统，是否继续？", "退出系统", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$storage.remove("token")
        this.$storage.remove("schoolName")
        this.$storage.remove("userInfo")
        this.$storage.remove("schoolId")
        this.$storage.remove("userId")
        this.$storage.remove("name")
        this.$storage.remove('isAuth')
        this.$storage.remove("isSuperAuth");
        this.$storage.remove("issuedBookAuth");
        this.$storage.remove("role");
        this.$storage.remove("customId");
        this.$router.push({ path: "/login" });
      })
    },
    userCenter() {
      this.$router.push("/user")
    }
  }
}
</script>
<!--内联样式-->
<style lang="scss" scoped>
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #c2c0c0;
}

/deep/ .el-menu-item:hover {
  color: #333;
}

/deep/ .el-menu--collapse .el-sub-menu.is-active .el-sub-menu__title {
  color: var(--el-menu-active-color) !important;
}

/deep/ .top-tp .el-menu-item {
  border-bottom: none !important;
}

/deep/ .top-tp .el-menu-item.is-active {
  border-bottom: none !important;
  position: relative;
}

/deep/ .top-tp .el-menu-item.is-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40%;
  height: 4px;
  background: #ff7f2e;
  transform: translateX(-50%);
  border-radius: 4px;
}

/deep/ .lftop .el-menu-item {
  justify-content: center;
  margin: 15px 15px 5px 30px;
  border-radius: 5px;
  background: #fff;
}

/deep/ .lftop .el-menu-item.is-active {
  background: #54d8a5;
  position: relative;
}

/deep/ .el-menu-item.is-active::after {
  background: #21ac74;
  position: absolute;
  right: 0;
  height: 100%;
  width: 2px;
  // content: "";
}

.hamburgerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #e7efff;
}

.hamburger.is-active {
  transform: rotate(180deg);
}

.zbh-logo {
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  // font-weight: bold;
  span {
    white-space: nowrap;
    font-size: 24px;

  }

  img {
    width: 23px;
    padding: 0 10px;
  }
}

#home-box {
  width: 100%;
  height: 100%;

  .el-menu--horizontal {
    // border-bottom: 5px solid #21ac74;
    height: 60px;
  }

  .el-main {
    background: #f7f8f8;
    padding: 0 !important;
  }

  .el-container {
    height: 100%;
  }

  .el-aside {
    width: 20%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    background: #f7f8f8;

    .zbh-logo {
      height: 8%;
      color: #F9F8F8;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
      background-color: #20222a;
    }

    .el-menu {
      border: none;
      height: calc(100vh - 60px);
      background-color: #f5f7f6;
      color: #fff;
    }

  }

  .el-header {
    padding: 0 0;
    text-align: center;
    height: 60px !important;
    // width: 99% !important;
    // border-bottom: 5px solid #21ac74;

    .el-row {
      height: 60px !important;
      padding-right: 7px;
    }

    .el-dropdown {
      margin-left: 10px !important;
    }
  }
}

.schoolDrawer /deep/ .el-drawer.ttb {
  width: 20%;
  margin-left: 70% !important;
}

.scrollbar-flex-content {
  display: flex;
  height: auto !important;
}

.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-danger-light-9);
  color: var(--el-color-danger);
}
</style>
