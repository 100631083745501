<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>学校维护</a></el-breadcrumb-item>
                <el-breadcrumb-item>学校详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card>
            <el-descriptions class="detailFF" title="基本信息">
                <el-descriptions-item label="客户">{{ form.customName }}</el-descriptions-item>
                <el-descriptions-item label="年级班制">{{ form.gradeClassIsmTxt }}</el-descriptions-item>
                <el-descriptions-item label="学校名称">{{ form.schoolName }}</el-descriptions-item>
                <el-descriptions-item label="学校简称">{{ form.shortName }}</el-descriptions-item>
                <!-- <el-descriptions-item label="管理员账号">
                    <el-tag size="small">{{ form.adminUsername }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="管理员姓名">{{ form.adminName }}</el-descriptions-item> -->
            </el-descriptions>
        </el-card>
        <el-card class="mt15">
            <el-row>
                <el-col :span="24">
                    <div class="mb15" style="display: flex;justify-content: space-between;">
                        <div class="card-header" style="font-size: 16px;width: 70px;text-align: left;">管理员
                        </div>
                    </div>
                    <el-form-item prop="adminList" label-width="0px" class="mr10" style="width: 100%">
                        <div class="bre9e w-100">
                            <el-table header-cell-class-name="bgf2" :data="form.adminList" style="width: 100%">
                                <el-table-column align="center" type="index" label="序号" width="60" />
                                <el-table-column width="150px" align="center" prop="adminName" label="管理员姓名">
                                    <template #default="scope">
                                        <div>
                                            {{ scope.row.adminName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="150px" align="center" prop="username" label="管理员账号">
                                    <template #default="scope">
                                        <div>{{ scope.row.username.substr(0, 3) + ' ' +
                                            scope.row.username.substr(3, 4) + ' ' +
                                            scope.row.username.substr(7, 4) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="170px" align="center" prop="adminType" label="类型">
                                    <template #default="scope">
                                        <div v-if="scope.row.adminType == 2">平台</div>
                                        <div v-if="scope.row.adminType == 3">业务</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="subjectList" label="学科">
                                    <template #default="scope">
                                        <div class="text-one" :title="scope.row.subjectTxt">{{ scope.row.subjectTxt }}</div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column v-if="schoolId" align="center" prop="classIdList" label="班级">
                                    <template #default="scope">
                                        <div class="text-one" :title="scope.row.classIdTxt">{{ scope.row.classIdTxt }}</div>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
        <el-col :span="24" class="mt15" style="height: 30px;">
            <el-col :span="6" class="pull-right">
                <el-input v-model="customName" placeholder="请输入号码或姓名" class="input-with-select">
                    <template #append>
                        <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                    </template>
                </el-input>
            </el-col>
        </el-col>
        <el-card class="mt15" style="margin-bottom: 100px;">
            <el-form class="detailFF" ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div class="el-descriptions__title" style="font-size: 16px;width: 70px;text-align: left;">学校教师
                            </div>
                            <div class="disnone" style="display: flex;">
                                <el-button type="primary" size="small" @click="newaddTeacher">
                                    新增教师
                                </el-button>
                                <el-button type="primary" size="small" @click="downloadTemplate">
                                    下载导入模板
                                </el-button>
                                <el-upload v-model:file-list="filesList" class="upload-demo"
                                    action="https://zbh.zbform.com/adminservice/zhkt/teacher/batchImport"
                                    :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                    :data="{ schoolId: schoolId }" accept=".xlsx" :before-upload="beforeUpload">
                                    <el-button class="ml15" type="primary" size="small">
                                        导入教师
                                    </el-button>
                                </el-upload>
                            </div>
                        </div>

                        <div class="bre9e w-100">
                            <el-table header-cell-class-name="bgf2" :data="teacherList" class="sssstt" style="width: 100%">
                                <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                                </el-table-column>
                                <el-table-column align="center" width="" prop="teacherName" label="姓名">
                                    <template #default="scope">
                                        <div>
                                            {{ scope.row.teacherName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="140" prop="adminAccount" label="账号">
                                    <template #default="scope">
                                        <div>{{ scope.row.username.substr(0, 3) + ' ' +
                                            scope.row.username.substr(3, 4) + ' ' +
                                            scope.row.username.substr(7, 4) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="" prop="gender" label="性别">
                                    <template #default="scope">
                                        <div style="" v-if="scope.row.gender || scope.row.gender == 0">
                                            {{ scope.row.gender == 0 ? '男' : '女' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="subjectTxt" label="学科">
                                </el-table-column>
                                <el-table-column align="center" width="150" label="编辑">
                                    <template #default="scope">
                                        <el-button link icon="Edit" @click.prevent="editTeacherDetail(scope.row)"
                                            type="primary">
                                            修改
                                        </el-button>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                            icon-color="#626AEF" title="确定删除该教师" @confirm="deleteTeacher(scope.row)">
                                            <template #reference>
                                                <el-button link icon="Delete" type="danger">
                                                    删除
                                                </el-button>
                                            </template>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <el-dialog :title="this.teacherForm.teacherId ? '修改' : '新增' + '教师'" v-model="isCreateTeacher" width="700px">
            <el-form v-if="isCreateTeacher" ref="teacherForm" :model="teacherForm" :rules="teacherFormRules"
                label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="教师账号" prop="username">
                            <el-input @blur="teacherForm.teacherName = ''" v-model="teacherForm.username"
                                placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="教师姓名" prop="teacherName">
                            <el-input v-model="teacherForm.teacherName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="学科" prop="subjectList">
                            <el-select v-model="teacherForm.subjectList" multiple placeholder="请选择学科" style="width: 100%;">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="性别:" prop="gender">
                            <el-radio-group v-model="teacherForm.gender">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="teacherSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isCreateTeacher = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { validatenull, isMobile } from '@/utils/validate'
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo, teacherQueryOrRegUser, teacherCreate, teacherUpdate, teacherDelete } from "@/api/adminservice/classOrganizaMain"
import { download } from "@/api/adminservice/public";

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            teacherList: [],
            customName: '',
            isCreateTeacher: false,
            token: this.$storage.get("token"),
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            schoolId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            subjectList: [],
            teacherForm: {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: ''
            },
            teacherFormRules: {
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }],
                teacherName: [
                    { required: true, message: "请输入教师姓名", trigger: "blur" },
                ],
                subjectList: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                gender: [
                    { required: true, message: "请选择性别", trigger: "change" },
                ]
            },
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                adminUsername: '',
                customId: '',
                gradeClassIsm: '',
                schoolName: '',
                shortName: '',
                gradeClassIsmList: [],
                gradeClassIsm: '',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                // customId:[
                //     { required: true, message: "请选择客户", trigger: "change" },
                // ],
                // gradeClassIsmList:[
                //     { required: true, message: "请选择年级班制", trigger: "change" },
                // ],
                // schoolName: [
                //     { required: true, message: "请输入学校名称", trigger: "blur" },
                // ],
                // shortName:[
                //     { required: true, message: "请输入学校简称", trigger: "blur" },
                // ],
                // adminUsername: [
                //     { required: true, message: "请输入管理员账号", trigger: "blur" },
                //     { validator: this.validateUsername, trigger: "blur" }
                // ],
                // adminName: [
                //     { required: true, message: "请输入管理员姓名", trigger: "blur" },
                // ],
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: ''
        };
    },
    created() {
        this.getZhktQueryDict()
    },
    mounted() {
        this.getList()

        if (this.$route.query.schoolId) {
            this.schoolId = this.$route.query.schoolId;
            this.getInfo()
        } else {
        }
    },
    computed: {
    },
    methods: {
        handleQuery() {
            this.teacherList = this.form.teacherList.filter(itemA => {
                return itemA.username.indexOf(this.customName) != -1 || itemA.teacherName.indexOf(this.customName) != -1
            })
        },
        deleteTeacher(row) {
            teacherDelete({ teacherId: row.teacherId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        validateUsername(rule, value, callback) {
            if (!isMobile(this.teacherForm.username)) {
                callback(new Error("手机号码格式不正确"))
            }
            teacherQueryOrRegUser({ username: this.teacherForm.username, trueName: this.teacherForm.teacherName, gender: this.teacherForm.gender }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("请输入正确账号"))
                } else {
                    if (this.teacherForm.teacherName == '') {
                        this.teacherForm.teacherName = res.data.data.trueName
                    }
                    if (this.teacherForm.gender == '') {
                        this.teacherForm.gender = res.data.data.gender
                    }
                    callback();
                }
            })
        },
        teacherSubmitForm() {
            this.$refs["teacherForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    this.teacherForm.schoolId = this.schoolId;
                    this.teacherForm.subject = this.teacherForm.subjectList.join(',')
                    if (this.teacherForm.teacherId) {
                        teacherUpdate(this.teacherForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("修改成功");
                                this.isCreateTeacher = false;
                                this.getInfo()
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        teacherCreate(this.teacherForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.isCreateTeacher = false;
                                this.getInfo()
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    }

                }
            });
        },
        editTeacherDetail(row) {
            this.teacherForm = JSON.parse(JSON.stringify(row));
            console.log(this.teacherForm);
            this.isCreateTeacher = true;
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E6%99%BA%E6%85%A7%E8%AF%BE%E5%A0%82%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入教师模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getInfo()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        newaddTeacher() {
            this.teacherForm = {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: ''
            }
            this.isCreateTeacher = true;
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
                zhktQueryDict({ code: 'subject' }).then(res => {
                    this.subjectList = res.data.data;
                    schoolInfo({ schoolId: this.schoolId }).then(res => {
                        this.form = res.data.data;
                        this.form.gradeClassIsmList = this.form.gradeClassIsm.split(',')
                        var gragradeClassIsmTxt = [];
                        this.form.gradeClassIsmList.forEach(j => {
                            this.classIsmList.forEach(m => {
                                if (j == m.dictKey) {
                                    gragradeClassIsmTxt.push(m.dictValue)
                                }
                            })
                        })
                        this.form.gradeClassIsmTxt = gragradeClassIsmTxt.toString('、')
                        this.form.teacherList.forEach(e => {
                            e.subjectList = e.subject.split(',');
                            var subjectTxt = [];
                            e.subjectList.forEach(j => {
                                this.subjectList.forEach(m => {
                                    if (j == m.dictKey) {
                                        subjectTxt.push(m.dictValue)
                                    }
                                })
                            })
                            e.subjectTxt = subjectTxt.toString('、')
                        })
                        this.teacherList = JSON.parse(JSON.stringify(this.form.teacherList));
                        this.form.adminList.forEach(e => {
                            e.subjectList = e.subjects.split(',');
                            var subjectTxt = [];
                            e.subjectList.forEach(j => {
                                this.subjectList.forEach(m => {
                                    if (j == m.dictKey) {
                                        subjectTxt.push(m.dictValue)
                                    }
                                })
                            })
                            e.subjectTxt = subjectTxt.toString('、')
                            var classIdTxt = []
                            e.classList.forEach(j => {
                                classIdTxt.push(j.className)
                            })
                            e.classIdTxt = classIdTxt.toString('、')

                        })
                    })
                })
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        // validateUsername(rule, value, callback) {
        //     qunQueryUser({ mobile: value }).then(res => {
        //         const result = res.data.data
        //         if (!result) {
        //             this.form.adminName = ''
        //             this.form.adminUserId = ''
        //             callback(new Error("该账号用户不存在"))
        //         } else {
        //             if (this.form.adminName == '') {
        //                 this.form.adminName = res.data.data.nickName;
        //             }
        //             this.form.adminUserId = res.data.data.userId;
        //             callback()
        //         }
        //     })
        // },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.gradeClassIsm = this.form.gradeClassIsmList && this.form.gradeClassIsmList.join(",")
                    if (this.$route.query.schoolId) {
                        schoolUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        schoolCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.$router.currentRoute._value.path == '/libraryClassOrganizaMainDetail') {
                this.replace('/libraryClassOrganizaMain')
            } else {
                this.replace('/classOrganizaMain')
            }
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}
</style>