import request from "@/utils/request";

let urlPrefix = "/adminservice/note/book"

// 创建展播
export const createBookShow =(data) =>{
    return request({
        url: urlPrefix+"/createBookShow",
        method:"post",
        data: data
    })
}

//更新展播
export const updateBookShow =(data) =>{
    return request({
        url: urlPrefix+"/updateBookShow",
        method:"post",
        data: data
    })
}

//展播列表
export const bookShowlist =(data) =>{
    return request({
        url: urlPrefix+"/bookShowlist",
        method:"post",
        data: data
    })
}

//展播笔迹信息   免登录， 在 request.js中有针对 authservice 开头的url设置 secret，在admin工程 WebConfiguration 中有拦截校验
export const showBook =(data) =>{
    return request({
        // url: urlPrefix+"/showBook",
        url: "/authservice/admin/bookShow/showBook",
        method:"post",
        data: data
    })
}


// 删除展播
export const deleteBookShow =(query) =>{
    return request({
        url: urlPrefix+"/deleteBookShow",
        method:"get",
        params: query
    })
}
// 展播详情
export const infoBookShow =(query) =>{
    return request({
        url: urlPrefix+"/infoBookShow",
        method:"get",
        params: query
    })
}