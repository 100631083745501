<template>
    <div class="app-container" style="height: calc(100vh - 130px);" @click="visible = false;">
        <el-card>
            <el-row>
                <el-col>
                    <!--查询条件-->
                    <div class="pull-right ml15">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="handleQuery">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0" v-if="!role.includes(1)">
                                        <el-form-item label="所属客户" class="w-100">
                                            <el-select class="w-100" @change="changeCustom"
                                                v-model="queryParams.customId" clearable placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="所属组织" class="w-100">
                                            <el-tree-select class="w-100" ref="tsd" default-expand-all node-key="orgId"
                                                clearable @change="changeOrg" v-model="queryParams.orgId" check-strictly
                                                :data="orgList" placeholder="请选择组织" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="所属群组" class="w-100">
                                            <el-select class="w-100" @change="changeQun" multiple
                                                v-model="queryParams.qunIds" clearable placeholder="请选择群组">
                                                <el-option v-for="item in qunList" :key="item.id"
                                                    :label="(item.qunName)" :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="书写时间" class="w-100">
                                            <el-date-picker class="w-100" value-format="x"
                                                v-model="queryParams.writeTime" type="daterange" range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="成员" class="w-100">
                                            <el-input class="w-100" v-model="queryParams.keywords"
                                                placeholder="请输入成员信息">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <!--下方列表-->
        <el-card shadow="never">
            <div style="display: flex;">
                <div style="width: 70%;">
                    <div class="bre9e" style="position: relative;">
                        <el-table header-cell-class-name="bgf2" @current-change="handleCurrentChange"
                            highlight-current-row class="sssstt"
                            :height="'calc(100vh - ' + (isSxShow ? '310' : '315') + 'px)'" v-loading="loading"
                            :data="dataXinList">
                            <el-table-column align="center" label="书写时间" prop="last_time" sortable>
                                <template #default="scope">
                                    <div>{{ timestampToTime(scope.row.last_time) }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="成员" prop="member_name">
                                <template #default="scope">
                                    <div class="text-one">
                                        {{ scope.row.member_name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="笔记本" prop="record_name">
                                <template #default="scope">
                                    <div class="text-one">
                                        {{ scope.row.record_name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="最后书写页" prop="page_num">
                                <template #default="scope">
                                    <div style="color: #409efe;" class="text-one cursor"
                                        @click.stop="showWriteInfo(scope.row)">
                                        {{ scope.row.page_num }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="群组" prop="qun_name">
                                <template #default="scope">
                                    <div class="text-one">
                                        {{ scope.row.qun_name }}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页组件-->
                    <div class="zdyfy">
                        <pagination :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
                    </div>
                </div>

                <!--右侧缩略图-->
                <div style="display: flex;align-items: center;justify-content: center;width: 30%;">
                    <div style="box-shadow: rgba(164, 176, 167, 0.3) 0px 0px 10px 0px ;">
                        <Canvasbox v-if="isCanvas && nowSelectRow && nowSelectRow.resourceType == 0"
                            :bookData="nowSelectRow.book"
                            :imgList="nowSelectRow.pageData ? nowSelectRow.pageData.illustrationList : []"
                            :imwidth="zdyWidth"
                            :imheight="nowSelectRow.book.pageHeight / nowSelectRow.book.pageWidth * zdyWidth"
                            :canvasid="nowSelectRow.id + '22323233' + index"
                            :picSrc="this.$store.state.zdy.imgUrl + '/book/' + nowSelectRow.resourceId + '/img/' + (nowSelectRow.lastWritePage ? nowSelectRow.lastWritePage : 1) + '.png_original'"
                            :detail="nowSelectRow.pageData ? nowSelectRow.pageData.strokeList : []">
                        </Canvasbox>
                        <Canvasbox
                            v-if="isCanvas && nowSelectRow && nowSelectRow.book && nowSelectRow.resourceType == 1"
                            :bookData="nowSelectRow.book"
                            :imgList="nowSelectRow.pageData ? nowSelectRow.pageData.illustrationList : []"
                            :imwidth="zdyWidth"
                            :imheight="nowSelectRow.book.pageHeight / nowSelectRow.book.pageWidth * zdyWidth"
                            :canvasid="nowSelectRow.id + '22323233' + index"
                            :picSrc="nowSelectRow.book.formUrl + '/' + (nowSelectRow.lastWritePage ? nowSelectRow.lastWritePage : 1) + '.png'"
                            :detail="nowSelectRow.pageData ? nowSelectRow.pageData.strokeList : []">
                        </Canvasbox>

                        <div v-if="!nowSelectRow"
                            style="display: flex;justify-content: center;align-items: center;padding: 20px;color: #909399;">
                            点击左侧数据查看笔迹缩略图
                        </div>
                    </div>
                </div>
            </div>
        </el-card>


        <!--查看详情弹框-->
        <el-dialog v-model="showBook" title="群笔记" :width="200">
            <div :style="'max-height: calc(100vh - ' + (isSxShow ? '212' : '205') + 'px);overflow-y: auto;'">
                <el-card class="cursor" style="margin-bottom: 10px;" v-for="(item, index) in memberRecordInfo" :key="index"
                    @click="goNewDetail(item)" :body-style="{ padding: '0px' }">
                    <div style="padding:14px 14px 0 14px;margin:0 auto;width: 130px;position: relative;">
                        <img v-if="item.resourceType == 0" style="width: 130px;height: auto;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/cover.png_original'"
                            alt="" class="image">
                        <img v-if="item.resourceType == 1" style="width: 130px;height: auto;"
                            :src="item.book.formUrl + '/1.png'" alt="" class="image">
                        <div
                            style="position: absolute;right: 0;top: 0;z-index: 999;background: rgb(56, 148, 255);color: #fff;font-size: 14px;padding: 2px 5px;border-radius: 15px;">
                            {{ item.memberName }}
                        </div>
                        <div v-if="!item.lastWritePage"
                            style="position: absolute;background: rgba(150, 145, 145, 0.5);z-index:33;top:14px;left:14px;right: 14px;bottom: 0;">

                        </div>

                    </div>
                    <div style="padding: 5px 14px 5px 14px">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div class="text-one fs-12" style="">{{
                                timestampToTime(item.lastWriteTime) ?
                                    timestampToTime(item.lastWriteTime) : '暂无书写时间'
                            }}</div>
                            <div class="fs-12">{{ item.writeCount }}页</div>
                        </div>
                    </div>
                </el-card>
                <el-empty style="width:100%;" v-if="memberRecordInfo.length == 0" description="暂无数据"></el-empty>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { noteotherBooks, notepageData, noteConditionBooks, noteBookQunBookList } from "@/api/adminservice/book";
import { queryTreeCustomOrg, queryCustoms, queryTreeCustomOrgWithCache } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { bookList } from "@/api/adminservice/public";
import { findModifiedTimesByPage, getBookWriteInfo } from "@/api/groupservice/writeHistory";

export default {
    name: "noteOtherBooks",
    components: {
    },
    data() {
        return {
            zdyWidth: 360,
            visible: false,
            isSxShow: true,
            timestampToTime,
            active: 1,
            isAuth: this.$storage.get('isAuth'),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            issuedBookAuth: this.$storage.get('issuedBookAuth'),
            orgList: [],
            // 遮罩层
            loading: false,
            // 总条数
            total: 0,
            dataXinList: [],
            // 查询参数
            queryParams: {
                customId: '',
                qunIds: '',
                orgId: '',
                keywords: '',
                writeTime: [],
                pageNum: 1, //分页参数
                pageSize: 10,
            },
            customList: [],
            qunList: [],
            bookList: [], //已经请求过的书籍信息
            qunIdList: [],
            role: '',
            nowSelectRow: '',//当前选中的数据
            isCanvas: false,//让canvas消失，然后出现，不然笔迹一直是之前的
            memberRecordInfo: [],//选中查看的本子信息
            showBook: false,
            tempQueryParams:{},
        };
    },
    created() {
        this.role = this.$storage.get("role")
        if (this.$storage.get("role").includes(1)) {
            // console.log("in")
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        }
        this.getCustoms();
        // console.log("params=", this.queryParams)
    },
    mounted() {
        //测试使用


    },
    methods: {
        goNewDetail(row) {
            if (row.lastWritePage) {
                // console.log(row)
                let routeUrl = this.$router.resolve({
                    path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
                    query: { userRecordId: row.id, bookData: JSON.stringify(row.book), qunRecordId: row.qunRecordId, qunId: row.qunId, queryParams: JSON.stringify(this.tempQueryParams) }
                })
                window.open(routeUrl.href, '_blank')
            }else{
                this.$message.error('暂无书写笔迹')
            }

        },
        showWriteInfo(row) {

            let resourceId = row.resource_id;
            let pageNum = row.page_num;
            let recordId = row.record_id;

            this.tempQueryParams = Object.assign({}, this.queryParams);
            this.tempQueryParams.resourceId = resourceId;
            this.tempQueryParams.pageNum = pageNum;
            this.tempQueryParams.sortType = 0;
            this.tempQueryParams.memberRecordId = recordId;


            noteConditionBooks(this.tempQueryParams).then(res => {
                // console.log(res);
                let retList = res.data.data;
                this.memberRecordInfo = retList;

                this.showBook = true;

            })
        },
        handleCurrentChange(row) {
            this.nowSelectRow = '';
            let resourceId = row.resource_id;
            let pageNum = row.page_num;
            let recordId = row.record_id;
            let find = false;
            //先从请求过的页面缓存数据中找，找不到再调接口
            this.bookList.forEach(book => {
                if (book.resourceId == resourceId && book.lastWritePage == pageNum && book.id == recordId) {
                    this.nowSelectRow = book;
                    find = true;
                }
            })
            if (!find) {
                getBookWriteInfo({
                    record_id: recordId,
                    resource_id: resourceId,
                    page_num: pageNum
                }).then(res => {
                    if (res.data.code == "1") {
                        this.nowSelectRow = res.data.data;
                        let bookInfo = res.data.data;
                        bookInfo.resourceType = row.resource_type
                        this.bookList.push(bookInfo);
                    }
                })
            }

            //为了让canvas重新绘制图画
            this.isCanvas = false;
            setTimeout(() => {
                this.isCanvas = true;
            }, 200)
        },
        changeQun(e) {
        },
        changeCollapse(activeNames) {
        },
        getTreeCustom() {
            queryTreeCustomOrgWithCache({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })
        },
        changeCustom() {
            this.qunIdList = [];
            this.qunBookList = [];
            this.orgList = [];
            this.queryParams.qunRecordId = '';
            this.queryParams.orgId = '';
            this.queryParams.qunId = '';
            this.qunList = [];
            this.queryParams.qunIds = [];
            // queryCustomQun({ customId: this.queryParams.customId }).then(res => {
            //     res.data.data.forEach(e => {
            //         this.qunIdList.push(e.id)
            //     })
            //     this.getTreeCustom()
            //     // this.getList()
            // })
            this.getTreeCustom()
        },
        changeOrg() {
            this.qunBookList = [];
            this.queryParams.qunRecordId = '';
            this.qunIdList = [];
            this.queryParams.qunId = ''
            this.queryParams.qunIds = [];
            this.qunList = [];

            queryCustomQun({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                this.qunList = res.data.data;
                // console.log(this.qunList);
            })
            // this.getList()
        },
        getCustoms() {
            this.customList = [];
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e, i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i, 1)
                        this.customList.unshift(e)
                    }
                })
                // console.log(this.customList);
            });
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (!this.queryParams.customId) {
                this.$message.warning("请选择客户")
                return;
            }
            if (!this.queryParams.orgId) {
                this.$message.warning("请选择组织")
                return;
            }

            if (!this.queryParams.qunIds || this.queryParams.qunIds.length <= 0) {
                this.$message.warning("至少选择一个群")
                return;
            }

            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }


            this.dataXinList = [];
            if (this.queryParams.writeTime && this.queryParams.writeTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.writeTime[0]
                this.queryParams.endTime = this.queryParams.writeTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }

            this.loading = true;
            this.dataXinList = []
            findModifiedTimesByPage(this.queryParams).then(res => {
                this.loading = false;
                // console.log(res);
                this.dataXinList = res.data.data.records;
                this.total = res.data.data.total;
                // console.log("dataXinList", this.dataXinList)
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId

                    // console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        // console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
.sdfsa .el-card {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-left: 10px;
    margin-right: 10px;
}

.sdfsa .el-card:last-child {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-right: auto;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.time {
    font-size: 12px;
    color: #999;
}

.bottom {
    margin-top: 3px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    padding: 0;
    min-height: auto;
}

.image {
    width: 100%;
    display: block;
}

/deep/ .el-tabs__content {
    height: calc(100vh - 180px);
    overflow: auto;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.czyform {
    position: absolute;
    left: 0;
    right: 0;
    top: 38px;
    background: #fff;
    z-index: 999;
    padding: 15px;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #409eff
}

.cjtag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    background: #f1f1f1;
    padding: 2px 7px;
    border-radius: 4px;
    color: #d44c2d;
}
</style>